import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Companys, FlowerLogo, IndbFlag, Star, Wave, Mapbg, CompanyVideo, Bg, Sketch } from '../../resources/images'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Slide } from 'react-awesome-reveal';

const Company = () => {
    return (
        <>
            <Container fluid='fluid' className='bg-[#114783] relative mt-[47px]'>
                <Row>
                    <Col md={6}>
                        <div className='p-3'>
                            <Slide direction='down' triggerOnce>
                                <p className='mb-0 text-[#ffff] font-Syne font-normal pt-[3rem] text-lg xsm:!pt-0 sm:!pt-0'><span className='text-sm'>Company/</span> About us</p><hr className='text-[#ffff] opacity-100'></hr>
                            </Slide>
                            <Slide direction='right' triggerOnce>
                                <h4 className='font-Syne font-bold pt-8 text-[#ffff] xsm:!pt-0'>Anything is possible with the right platform</h4>
                                <p className='mb-0 font-Syne text-[#ffff]'>We're focussed on building exceptional digital experience platforms,</p>
                                <p className='mb-0 font-Syne text-[#ffff]'>because we believe with the right platform anything is possible.</p>
                            </Slide>
                        </div>
                    </Col>
                    <Col className='!pr-0 xsm:hidden sm:hidden' md={6}>
                        {/* <Image className='h-full w-full' src={Companies} /> */}
                        <Slide direction='left' triggerOnce>
                            <Image className='h-[350px] w-full' src={Companys} />
                        </Slide>
                    </Col>
                </Row>
                <Image className='h-[60px] absolute left-0 -bottom-6 w-full xsm:hidden sm:hidden' src={Wave} />
            </Container>
            <Container>
                <div className='mt-8'>
                    <Slide direction='up' triggerOnce>
                        <h4 className='font-Prompt text-center text-[#000000] xsm:text-[20px]'>What does success look like?</h4>
                    </Slide>
                    <p className='font-Prompt text-center text-[#737373] pt-6 xsm:text-sm'>Our happy place is in solving complex problems, delivering tangible digital transformation to create the most value.Our own strategy, design and development teams work as an extension of our clients’ internal teams to measurably improve the customer experience and deliver this value.</p>
                    <video className="w-100" height="auto" autoPlay loop muted>
                        <source
                            src={CompanyVideo}
                            type="video/mp4"
                            allowFullScreen
                        />
                    </video>
                    <p className='font-Prompt text-[#737373] pt-3 text-sm'>*BTW-Life at FujiSakura</p>
                </div>
                <div>
                    <Slide direction='down' triggerOnce>
                        <h4 className='font-Prompt text-center text-[#000000]'>Who we are?</h4>
                    </Slide>
                    <Slide direction='up' triggerOnce>
                        <p className='font-Prompt text-center text-[#737373] pt-6 mb-0 xsm:text-sm'>We are a family-owned and founder-managed company that started off in 2018.</p>
                        <p className='font-Prompt text-center text-[#737373] pt-3 mb-0 xsm:text-sm'>We created FujiSakura, both Japan and India based custom software development company, to make a difference through our engineering skills and culture that stands out through our style and way of working.</p>
                        <p className='font-Prompt text-center text-[#737373] pt-3 mb-0 xsm:text-sm'>From the founders to the management and the execution team, our japanese culture is the bedrock of everything we do. Along with deep technical expertise and accountability, it leads us to create remarkable software solutions for our partners.</p>
                    </Slide>
                </div>
                <div className='mt-5 relative'>
                    <Row>
                        <Col md={7}>
                            <Slide className='z-10 relative' direction='up' triggerOnce>
                                <div>
                                    <div className='z-10 relative bg-[#ffffff99] p-12 rounded-xl shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)] xsm:p-4 md:p-4'>
                                        <div className='flex gap-x-3'>
                                            <h6 className='mb-0 font-JosefinSans font-bold text-[#333333]'>INDIA</h6>
                                            <Image className='w-[26px] h-[16px]' src={IndbFlag} />
                                        </div>
                                        <Row className='pt-3'>
                                            <Col md={4}>
                                                <div>
                                                    <p className='mb-[0px] text-[#737373] font-Prompt xsm:mb-[5px] xsm:mb-[5px]'>Company Name</p>
                                                </div>
                                            </Col>
                                            <Col md={8}>
                                                <div>
                                                    <p className='mb-0 text-[#333333] font-JosefinSans xsm:text-sm'>FUJISAKURA TECHNOLOGIES (P) LTD.,</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='pt-3'>
                                            <Col md={4}>
                                                <div>
                                                    <p className='mb-[0px] text-[#737373] font-Prompt xsm:mb-[5px]'>Address & Phone number</p>
                                                </div>
                                            </Col>
                                            <Col md={8}>
                                                <div>
                                                    <p className='mb-0 text-[#333333] font-JosefinSans xsm:text-sm'>Plot No. 22 & 23,1st Floor (1A & 1B),Sri Durga Enclave - Phase II,River View Residency 2nd Street,Karapakkam, Off OMR,Chennai – 600 097</p>
                                                    <p className='mb-0 text-[#333333] font-JosefinSans xsm:text-sm'>Tamil Nadu, IndiaTel: <a href='tel:+ 91-44-2952-0058' className='text-[#333333] font-JosefinSans'>+ 91-44-2952-0058</a></p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='pt-3'>
                                            <Col md={4}>
                                                <div>
                                                    <p className='mb-[0px] text-[#737373] font-Prompt xsm:mb-[5px]'>Date of establishment</p>
                                                </div>
                                            </Col>
                                            <Col md={8}>
                                                <div>
                                                    <p className='mb-0 text-[#333333] font-JosefinSans xsm:text-sm'>March 2018</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='pt-3'>
                                            <Col md={4}>
                                                <div>
                                                    <p className='mb-[0px] text-[#737373] font-Prompt xsm:mb-[5px]'>Representative Director</p>
                                                </div>
                                            </Col>
                                            <Col md={8}>
                                                <div>
                                                    <p className='mb-0 text-[#333333] font-JosefinSans xsm:text-sm'>Pazhamalai Jagadeesan</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='pt-3'>
                                            <Col md={4}>
                                                <div>
                                                    <p className='mb-[0px] text-[#737373] font-Prompt xsm:mb-[5px]'>Directors</p>
                                                </div>
                                            </Col>
                                            <Col md={8}>
                                                <div>
                                                    <p className='mb-0 text-[#333333] font-JosefinSans xsm:text-sm'>Satheeshkannan Chandrasekaran,</p>
                                                    <p className='mb-0 text-[#333333] font-JosefinSans xsm:text-sm'>Satheesh Chelliah,</p>
                                                    <p className='mb-0 text-[#333333] font-JosefinSans xsm:text-sm'>Madhavaramanujam Rajendran.</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Slide>
                        </Col>
                        {/* <Col md={6}>
                            <div className='z-10 relative bg-[#ffffff99] p-12 rounded-xl shadow-[0px_4px_16px_rgba(17,17,26,0.1),_0px_8px_24px_rgba(17,17,26,0.1),_0px_16px_56px_rgba(17,17,26,0.1)]'>
                                <div className='flex items-start gap-x-3'>
                                    <h6 className='mb-0 font-JosefinSans font-bold text-[#333333]'>INDIA</h6>
                                    <Image className='w-[26px] h-[16px]' src={IndbFlag} />
                                </div>
                                <Row className='pt-3'>
                                    <Col md={4}>
                                        <div>
                                            <p className='mb-0 text-[#737373] font-Prompt'>Company Name</p>
                                        </div>
                                    </Col>
                                    <Col md={8}>
                                        <div>
                                            <p className='mb-0 text-[#333333] font-JosefinSans'>FUJISAKURA TECHNOLOGIES (P) LTD.,</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='pt-3'>
                                    <Col md={4}>
                                        <div>
                                            <p className='mb-0 text-[#737373] font-Prompt'>Address & Phone number</p>
                                        </div>
                                    </Col>
                                    <Col md={8}>
                                        <div>
                                            <p className='mb-0 text-[#333333] font-JosefinSans'>Plot No. 22 & 23,1st Floor (1A & 1B),Sri Durga Enclave - Phase II,River View Residency 2nd Street,Karapakkam, Off OMR,Chennai – 600 097</p>
                                            <p className='mb-0 text-[#333333] font-JosefinSans'>Tamil Nadu, IndiaTel: <a href='tel:+ 91-44-2952-0058' className='text-[#333333] font-JosefinSans'>+ 91-44-2952-0058</a></p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='pt-3'>
                                    <Col md={4}>
                                        <div>
                                            <p className='mb-0 text-[#737373] font-Prompt'>Date of establishment</p>
                                        </div>
                                    </Col>
                                    <Col md={8}>
                                        <div>
                                            <p className='mb-0 text-[#333333] font-JosefinSans'>March 2018</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='pt-3'>
                                    <Col md={4}>
                                        <div>
                                            <p className='mb-0 text-[#737373] font-Prompt'>Representative Director</p>
                                        </div>
                                    </Col>
                                    <Col md={8}>
                                        <div>
                                            <p className='mb-0 text-[#333333] font-JosefinSans'>Pazhamalai Jagadeesan</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='pt-3'>
                                    <Col md={4}>
                                        <div>
                                            <p className='mb-0 text-[#737373] font-Prompt'>Directors</p>
                                        </div>
                                    </Col>
                                    <Col md={8}>
                                        <div>
                                            <p className='mb-0 text-[#333333] font-JosefinSans'>Satheeshkannan Chandrasekaran,</p>
                                            <p className='mb-0 text-[#333333] font-JosefinSans'>Satheesh Chelliah,</p>
                                            <p className='mb-0 text-[#333333] font-JosefinSans'>Madhavaramanujam Rajendran.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col> */}
                    </Row>
                    <Image className='absolute top-0 w-full h-full left-0' src={Mapbg} />
                </div>

                {/* <div className='flex flex-col justify-center bg-slat-100'>
                    <div className='group w-96 h-96 [prespective:1000px]'>
                        <div className='relative w-full h-full rounded-xl shadow-xl transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]'>
                            <div className='absolute inset-0'>
                                <Image className='h-full w-full rounded-xl object-cover shadow-xl shadow-black/40' src={Bg} />
                            </div>
                            <div className='absolute inset-0 h-full w-full rounded-xl shadow-xl shadow-black/40 p-12 text-center [transform:rotateY(180deg)] [backface-visibility:hidden]'>
                                <h5 className='font-Prompt text-center text-[#000000] mb-3'>Ready for your next adventure with FujiSakura?</h5>
                                <p className='mb-0 font-Prompt text-center text-[#737373]'>If you have the right attitude and skills to nurture, we offer you the perfect</p>
                                <p className='mb-0 font-Prompt text-center text-[#737373]'>infrastructure to grow – both professionally and personally.</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className='mt-5'>
                    <h4 className='font-Prompt text-center text-[#000000]'>Organization's Milestones</h4>
                    <p className='font-Prompt text-center text-[#737373] pt-6 pb-6 mb-0 xsm:text-sm'>We are a family-owned and founder-managed company that started off in 2018.</p>
                    <VerticalTimeline lineColor={'#D9D9D9'}>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work my-4"
                            contentStyle={{ background: '#FFA600', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  #FFA600' }}
                            // date="2011 - present"
                            iconStyle={{ background: '#FFA600', color: '#fff', display: 'flex' }}
                            icon={<Image className='w-[30px] h-[30px] m-auto' src={Star} />}
                        >
                            <h3 className="vertical-timeline-element-title font-Prompt font-normal">March 2018</h3>
                            <p className='font-Prompt !font-light'>
                                Established in India with 7 employees
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work my-4"
                            // date="2010 - 2011"
                            contentStyle={{ background: '#00837E', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  #00837E' }}
                            iconStyle={{ background: '#00837E', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title font-Prompt font-normal">June 2018</h3>
                            <p className='font-Prompt !font-light'>
                                Established a Japan corporationRepresentative director SatheeshKannan inaugurated
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work my-4"
                            contentStyle={{ background: '#FF5E5B', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  #FF5E5B' }}
                            iconStyle={{ background: '#FF5E5B', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title font-Prompt font-normal">Decemer 2018</h3>
                            <p className='font-Prompt !font-light'>
                                Launched solution business (contract, SES). First project kickoff
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work my-4"
                            iconStyle={{ background: '#8B5C6C', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  #8B5C6C' }}
                            contentStyle={{ background: '#8B5C6C', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title font-Prompt font-normal">January 2019</h3>
                            <p className='font-Prompt !font-light'>
                                15 Employees
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education my-4"
                            iconStyle={{ background: '#FF8347', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  #FF8347' }}
                            contentStyle={{ background: '#FF8347', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title font-Prompt font-normal">April 2019</h3>
                            <p className='font-Prompt !font-light'>
                                Launched SAP business. First project kickoff
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education my-4"
                            iconStyle={{ background: '#00A985', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  #00A985' }}
                            contentStyle={{ background: '#00A985', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title font-Prompt font-normal">December 2019</h3>
                            <p className='font-Prompt !font-light'>
                                Obtained ISO / ISMS Certifications in India
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education my-4"
                            iconStyle={{ background: '#FFA600', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  #FFA600' }}
                            contentStyle={{ background: '#FFA600', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title font-Prompt font-normal">January 2020</h3>
                            <p className='font-Prompt !font-light'>
                                30 employees and
                                Launched SAP ARIBA Division. First project kickoff
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education my-4"
                            iconStyle={{ background: '#00837E', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  #00837E' }}
                            contentStyle={{ background: '#00837E', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title font-Prompt font-normal">March 2020</h3>
                            <p className='font-Prompt !font-light'>
                                45 employees
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education my-4"
                            iconStyle={{ background: '#FF5E5B', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  #FF5E5B' }}
                            contentStyle={{ background: '#FF5E5B', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title font-Prompt font-normal">October 2020</h3>
                            <p className='font-Prompt !font-light'>
                                Representative Director Yozo Minowa appointed
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education my-4"
                            iconStyle={{ background: '#8B5C6C', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  #8B5C6C' }}
                            contentStyle={{ background: '#8B5C6C', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title font-Prompt font-normal">December 2020</h3>
                            <p className='font-Prompt !font-light'>
                                Launched consultant business
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education my-4"
                            iconStyle={{ background: '#FF8347', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  #FF8347' }}
                            contentStyle={{ background: '#FF8347', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title font-Prompt font-normal">May 2021</h3>
                            <p className='font-Prompt !font-light'>
                                Launched Java Business. First Project Kick Off
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education my-4"
                            iconStyle={{ background: '#00A985', color: '#fff' }}
                            contentArrowStyle={{ borderRight: '7px solid  #00A985' }}
                            contentStyle={{ background: '#00A985', color: '#fff' }}
                        >
                            <h3 className="vertical-timeline-element-title font-Prompt font-normal">January 2022</h3>
                            <p className='font-Prompt !font-light'>
                                60 employees
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff', display: 'flex' }}
                            icon={<Image className='w-[30px] h-[30px] m-auto' src={Star} />}
                        />
                    </VerticalTimeline>
                </div>
            </Container>
        </>
    )
}

export default Company