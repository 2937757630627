import React from 'react'
import { Button, Col, Container, FloatingLabel, Form, Image, Row } from 'react-bootstrap'
import { Contacts, IndbFlag, Indiaimg, Iso1, Iso2, Japanimg, JapbFlag, Nasscom, PingMsg, Wave } from '../../resources/images'
import { Slide, Fade } from 'react-awesome-reveal'

const Contact = () => {
    return (
        <>
            <Container fluid='fluid' className='bg-[#604827] relative mt-[47px]'>
                <Row>
                    <Col md={6}>
                        <div className='p-3'>
                            <Slide direction='down' triggerOnce>
                                <p className='mb-0 text-[#ffff] font-Syne font-normal pt-[3rem] text-lg xsm:!pt-0 sm:!pt-0'>Contact</p><hr className='text-[#ffff] opacity-100'></hr>
                            </Slide>
                            <Slide direction='right' triggerOnce>
                                <h4 className='font-Syne font-bold pt-8 text-[#ffff] xsm:pt-4 sm:pt-4'>Get In Touch</h4>
                                <p className='mb-0 font-Syne text-[#ffff]'>We’re Determined to help our new customers in reaching new heights with Web Mobility and ERP Solutions.</p>
                            </Slide>
                            {/* <p className='mb-0 font-Syne text-[#ffff]'>because we believe with the right platform anything is possible.</p> */}
                        </div>
                    </Col>
                    <Col className='!pr-0' md={6}>
                        <Slide direction='left' triggerOnce>
                            <Image className='h-[350px] w-full xsm:hidden sm:hidden' src={Contacts} />
                        </Slide>
                    </Col>
                </Row>
                <Image className='h-[60px] absolute left-0 -bottom-6 w-full xsm:hidden sm:hidden' src={Wave} />
            </Container>
            <Container className='mt-[3rem] xsm:mt-[1.25rem] sm:mt-[1.25rem]'>
                <Row>
                    <Col md={4} className='xsm:mb-8 sm:mb-8'>
                        <Slide direction='up' triggerOnce>
                            <div>
                                <h4 className='font-Prompt text-[#000000]'>Our Culture</h4>
                                <p className='mb-0 font-Prompt text-[#737373]'>Our primary focus always remains on the employees, they lead the organization towards success by providing the strategic values. We have been successful to reach at the new heights by keeping our employees on the first priority. Our aim is not only to enhance the professional skills, but also the personal skills of an employee. If you feel this is somewhere where you can grow and learn then come join us!</p>
                            </div>
                        </Slide>
                    </Col>
                    <Col md={4} className='xsm:mb-8 sm:mb-8'>
                        <Slide direction='up' triggerOnce>
                            <div className='border-[1px] border-[#e6ebf363] hover:shadow-[0_-1px_16px_2px_rgba(174,_184,_186,_.78)] transition duration-700 hover:transition hover:duration-700 hover:ease-in-out rounded-lg bg-[#ffff] xsm:hover:shadow-none sm:hover:shadow-none'>
                                <div className='p-9 pb-0 xsm:p-0 sm:pt-0 md:p-[.5rem]'>
                                    <h6 className='font-Prompt text-[#282829] font-semibold'>Sales Office</h6>
                                    <p className='flex items-center text-[#164CC2] font-JosefinSans'>Tokyo, Japan<span><Image className='w-[28px] h-[18px] ml-3' src={JapbFlag} /></span></p>
                                    <p className='font-Prompt text-[#737373]'>FujiSakura Technologies Inc,6F, Kitagawa Building,2-4-12 HigashiNihombashi,Chuo-Ku,Tokyo 103-0004, JapanPH : <a href="tel: 03-5829-6208" className='font-Prompt text-[#737373] no-underline'>03-5829-6208</a></p>
                                </div>
                                <Image src={Japanimg} />
                            </div>
                        </Slide>
                    </Col>
                    <Col md={4} className='xsm:mb-8'>
                        <Slide direction='up' triggerOnce>
                            <div className='border-[1px] border-[#e6ebf363] hover:shadow-[0_-1px_16px_2px_rgba(174,_184,_186,_.78)] transition duration-700 hover:transition hover:duration-700 hover:ease-in-out rounded-lg bg-[#ffff] xsm:hover:shadow-none sm:hover:shadow-none'>
                                <div className='p-9 pb-0 xsm:p-0 sm:pt-0 md:p-[.5rem]'>
                                    <h6 className='font-Prompt text-[#282829] font-semibold'>Administrative Office</h6>
                                    <p className='flex items-center text-[#164CC2] font-JosefinSans'>Chennai, India<span><Image className='w-[26px] h-[16px] ml-3' src={IndbFlag} /></span></p>
                                    <p className='font-Prompt text-[#737373]'>FujiSakura Technologies Pvt. Ltd,Plot No. 22 & 23,1st Floor (1A & 1B),Sri Durga Enclave - Phase II,River View Residency 2nd Street,Karapakkam,Chennai - 600 097.TamilNadu, IndiaTel: <a href="tel: +91 44 2952 0058" className='font-Prompt text-[#737373] no-underline'>+ 91-44-2952-0058</a></p>
                                </div>
                                <Image src={Indiaimg} />
                            </div>
                        </Slide>
                    </Col>
                </Row>
            </Container>
            <div className='mt-[3rem] bg-[#FAFAFA] p-[3rem] xsm:p-0 xsm:m-0 md:p-[1rem]'>
                <Container>
                    <h4 className='font-Prompt text-[#000000]'>Contact</h4>
                    <Row>
                        <Col md={6} className='xsm:mb-8 sm:mb-8'>
                            <p className='font-Prompt text-[#737373]'>Fill out our contact form, email or call us directly. Whatever works for you, we’ll be here!</p>
                            <Row>
                                <Col md={8}>
                                    <div>
                                        <Form>
                                            <Fade cascade damping={0.9} triggerOnce>
                                                <FloatingLabel className='mb-3' controlId="floatingInputGrid" label="Name">
                                                    <Form.Control className='font-Prompt !text-[#000000] !text-sm bg-transparent !border-transparent !border-b-1 !rounded-none !border-b-[#000000] !shadow-none' type="text" placeholder="name" />
                                                </FloatingLabel>
                                            </Fade>
                                            <Fade cascade damping={0.9} triggerOnce>
                                                <FloatingLabel className='mb-3' controlId="floatingInputGrid" label="Phone Number">
                                                    <Form.Control className='font-Prompt !text-[#000000] !text-sm bg-transparent !border-transparent !border-b-1 !rounded-none !border-b-[#000000] !shadow-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none' type="number" placeholder="number" />
                                                </FloatingLabel>
                                            </Fade>
                                            <Fade cascade damping={0.9} triggerOnce>
                                                <FloatingLabel className='mb-3' controlId="floatingInputGrid" label="Email address">
                                                    <Form.Control className='font-Prompt !text-[#000000] !text-sm bg-transparent !border-transparent !border-b-1 !rounded-none !border-b-[#000000] !shadow-none' type="email" placeholder="name@example.com" />
                                                </FloatingLabel>
                                            </Fade>
                                            <Fade cascade damping={0.9} triggerOnce>
                                                <FloatingLabel className='mb-3' controlId="floatingTextarea" label="Comments">
                                                    <Form.Control className='font-Prompt !text-[#000000] !text-sm bg-transparent !border-transparent !border-b-1 !rounded-none !border-b-[#000000] !shadow-none resize-none' as="textarea" placeholder="Leave a comment here" />
                                                </FloatingLabel>
                                            </Fade>
                                            <Form.Check
                                                type="checkbox"
                                                className='font-Prompt !text-[#737373] !text-xs !shadow-none'
                                                name="remember"
                                                label={"I consent to having this website store my submitted information so they can respond to my enquiry."}
                                            />
                                            <Button className="w-full mt-5 !bg-[#4379EE] border-1 !border-[#4379EE] px-3 py-1 !text-sm !rounded-md">Submitt</Button>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <div className='bg-[#e8edf9] py-5 px-8 rounded-xl text-right'>
                                <Image className='w-[85px]' src={PingMsg} />
                                <div className='mb-3'>
                                    <p className='font-Prompt text-[#737373] text-base mb-1'>or call us</p>
                                    <a href="tel: +91 44 2952 0058" className='font-Prompt text-[#737373] text-base mb-1 no-underline'>+91 44 2952 0058</a>
                                </div>
                                <div className='mb-3'>
                                    <p className='font-Prompt text-[#737373] text-base mb-1'>or send us an e-mail</p>
                                    <a href="mailto: hr@fujisakuratech.com" className='font-Prompt text-[#737373] text-base mb-1 no-underline'>hr@fujisakuratech.com</a>
                                </div>
                                <div className='mb-3'>
                                    <p className='font-Prompt text-[#737373] text-base mb-3'>FujiSakura Technologies Pvt. Ltd.</p>
                                    <p className='font-Prompt text-[#737373] text-base mb-1 no-underline'>Plot No. 22 & 23,1st Floor (1A & 1B),Sri</p>
                                    <p className='font-Prompt text-[#737373] text-base mb-1 no-underline'> Durga Enclave - Phase II,River View</p>
                                    <p className='font-Prompt text-[#737373] text-base mb-1 no-underline'>Residency 2nd</p>
                                    <p className='font-Prompt text-[#737373] text-base mb-1 no-underline'>Street,Karapakkam,Chennai - 600 097</p>
                                </div>
                                <Row className='items-center'>
                                    <Col xs={4}>
                                        <div>
                                            <Image className='w-[80px] ml-auto' src={Iso1} />
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <div>
                                            <Image className='w-[80px] ml-auto' src={Iso2} />
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <div>
                                            <Image className='w-[120px] ml-auto' src={Nasscom} />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Contact