import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Director1, Director2, Director3, Director4, Director5, IndbFlag, JapbFlag } from '../../resources/images'
import { Slide } from 'react-awesome-reveal'

const Leadership = () => {
    return (
        <Container className='relative mt-[47px]'>
            <Slide direction='up' triggerOnce>
                <div className='pt-3'>
                    <h4 className='font-JosefinSans text-center text-[#282829] font-bold mb-3'>Our Leaders</h4>
                    <Image className='w-[250px] h-[250px] m-auto' src={Director1} />
                    <h6 className='font-JosefinSans text-center text-[#282829] pt-4'>Yozo Minowa</h6>
                    <p className='flex items-center justify-center text-center text-[#737373] font-Syne'>Director - Japan<span><Image className='w-[28px] h-[18px] ml-3' src={JapbFlag} /></span></p>
                </div>
            </Slide>
            <Row className='mt-5'>
                <Col md={6} lg={6} xl={3}>
                    <div>
                        <Slide direction='up' triggerOnce>
                            <Image className='w-[250px] h-[250px] m-auto' src={Director2} />
                        </Slide>
                        <h6 className='font-JosefinSans text-center text-[#282829] pt-4'>Satheeshkannan Chandrasekaran</h6>
                        <p className='flex items-center justify-center text-center text-[#737373] font-Syne'>Director - Japan<span><Image className='w-[28px] h-[18px] ml-3' src={JapbFlag} /></span></p>
                    </div>
                </Col>
                <Col md={6} lg={6} xl={3}>
                    <div>
                        <Slide direction='up' triggerOnce>
                            <Image className='w-[250px] h-[250px] m-auto' src={Director3} />
                        </Slide>
                        <h6 className='font-JosefinSans text-center text-[#282829] pt-4'>Satheesh Chelliah</h6>
                        <p className='flex items-center justify-center text-center text-[#737373] font-Syne'>Director - Japan<span><Image className='w-[28px] h-[18px] ml-3' src={JapbFlag} /></span></p>
                    </div>
                </Col>
                <Col md={6} lg={6} xl={3}>
                    <div>
                        <Slide direction='up' triggerOnce>
                            <Image className='w-[250px] h-[250px] m-auto' src={Director4} />
                        </Slide>
                        <h6 className='font-JosefinSans text-center text-[#282829] pt-4'>Madhavaramanujam Rajendran</h6>
                        <p className='flex items-center justify-center text-center text-[#737373] font-Syne'>Director - Japan<span><Image className='w-[28px] h-[18px] ml-3' src={JapbFlag} /></span></p>
                    </div>
                </Col>
                <Col md={6} lg={6} xl={3}>
                    <div>
                        <Slide direction='up' triggerOnce>
                            <Image className='w-[250px] h-[250px] m-auto' src={Director5} />
                        </Slide>
                        <h6 className='font-JosefinSans text-center text-[#282829] pt-4'>Pazhamalai Jagadeesan</h6>
                        <p className='flex items-center justify-center text-center text-[#737373] font-Syne'>Director - India<span><Image className='w-[28px] h-[18px] ml-3' src={IndbFlag} /></span></p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Leadership