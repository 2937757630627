import React from 'react'
import { Image } from 'react-bootstrap'
import { JobArrow } from '../../resources/images'
import { NavLink } from 'react-router-dom'
import { Slide } from 'react-awesome-reveal'

const Applyjob = () => {
    return (
        <>
            <div className='mt-4'>
                <Slide direction='up' triggerOnce>
                    <NavLink className='no-underline' to={`/carrer/2`}>
                        <div className='flex justify-between items-center border-b-[1px] cursor-pointer border-[#969696] py-4'>
                            <p className='font-Prompt text-[#000000] mb-0'>Bilingual Technical Support Engineer (JP-EN Translation)</p>
                            <Image className='w-[40px]' src={JobArrow} />
                        </div>
                    </NavLink>
                </Slide>
                <Slide direction='up' triggerOnce>
                    <div className='flex justify-between items-center border-b-[1px] cursor-pointer border-[#969696] py-4'>
                        <p className='font-Prompt text-[#000000] mb-0'>React JS developer</p>
                        <Image className='w-[40px]' src={JobArrow} />
                    </div>
                </Slide>
                <Slide direction='up' triggerOnce>
                    <div className='flex justify-between items-center border-b-[1px] cursor-pointer border-[#969696] py-4'>
                        <p className='font-Prompt text-[#000000] mb-0'>Software Developer</p>
                        <Image className='w-[40px]' src={JobArrow} />
                    </div>
                </Slide>
            </div>
        </>
    )
}

export default Applyjob