import React from 'react'
import { Button, Col, Container, Form, Image, ListGroup, Row } from 'react-bootstrap'
import { JobBackarrow, JoinTeam, Upload } from '../../resources/images'
import { Link } from 'react-router-dom'

const Jobdetails = () => {
    return (
        <>
            <Container className='mt-[47px]'>
                <div>
                    <Image src={JoinTeam} />
                </div>
            </Container>
            <Container fluid='fluid'>
                <Row>
                    <Col className='bg-[#86B165] !px-[50px] py-[50px] xsm:!px-[20px] sm:px-[20px] xsm:!py-[20px] sm:py-[20px]' md={4}>
                        <div>
                            <Link to={`/carrer`}>
                                <Image className='w-[30px] h-[16px] mb-5 cursor-pointer' src={JobBackarrow} />
                            </Link>
                            <h5 className='font-Prompt text-[#fafafa]'>Bilingual Technical Support Engineer (JP-EN Translation)</h5>
                            <p className='font-Prompt text-[#fafafa] font-light text-sm'>Remote*</p>
                            <Button className='!bg-[#608742] border-0 !py-[5px] !px-[26px] !rounded-sm !font-Prompt !text-sm'>Apply</Button>
                        </div>
                    </Col>
                    <Col className='bg-[#d7e4cd] !px-[50px] py-[50px] xsm:!px-[20px] sm:px-[20px] xsm:!py-[20px] sm:py-[20px] xsm:bg-transparent sm:bg-transparent' md={8}>
                        <div className='mb-5'>
                            <h6 className='font-Prompt text-[#000000] font-bold mb-4'>Job Description</h6>
                            <p className='font-Prompt text-[#000000] font-normal text-sm'>We are looking to hire a translator for a full- time Japanese Translator role ( English to Japanese language and Japanese to English language ) to join our growing international team of translators.</p>
                            <ListGroup>
                                <ListGroup.Item className='border-0 !text-[#000000] bg-transparent text-sm font-Prompt'>1. Responsible for translating all text from English to Japanese and vice versa.</ListGroup.Item>
                                <ListGroup.Item className='border-0 !text-[#000000] bg-transparent text-sm font-Prompt'>2. Handling of applications like Salesforce, FAQ, Dataspider and vice versa.</ListGroup.Item>
                                <ListGroup.Item className='border-0 !text-[#000000] bg-transparent text-sm font-Prompt'>3. Handling Japanese customer queries.</ListGroup.Item>
                                <ListGroup.Item className='border-0 !text-[#000000] bg-transparent text-sm font-Prompt'>4. Responsible to interpret English to Japanese and vice versa.</ListGroup.Item>
                                <ListGroup.Item className='border-0 !text-[#000000] bg-transparent text-sm font-Prompt'>5. Communicate with Japanese via mails Both English to Japanese and Japanese to English.</ListGroup.Item>
                                <ListGroup.Item className='border-0 !text-[#000000] bg-transparent text-sm font-Prompt'>6. Support customer via phone call through English and Japanese.</ListGroup.Item>
                            </ListGroup>
                        </div>
                        <div className='mb-5'>
                            <h6 className='font-Prompt text-[#000000] font-bold mb-4'>Criteria</h6>
                            <Row>
                                <Col sm={3}>
                                    <p className='font-Prompt text-[#000000] font-normal text-sm'>Experience</p>
                                </Col>
                                <Col sm={3}>
                                    <p className='font-Prompt text-[#000000] font-normal text-sm'>02</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <p className='font-Prompt text-[#000000] font-normal text-sm'>Qualification</p>
                                </Col>
                                <Col sm={3}>
                                    <p className='font-Prompt text-[#000000] font-normal text-sm'>BE/MCA/BCA/B.Sc Computer Science</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <p className='font-Prompt text-[#000000] font-normal text-sm'>Japanese Level</p>
                                </Col>
                                <Col sm={3}>
                                    <p className='font-Prompt text-[#000000] font-normal text-sm'>JLPT N5/N4/N3</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <p className='font-Prompt text-[#000000] font-normal text-sm'>Location</p>
                                </Col>
                                <Col sm={3}>
                                    <p className='font-Prompt text-[#000000] font-normal text-sm'>Chennai</p>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            <h6 className='font-Prompt text-[#000000] font-bold mb-4'>Submit Your Application</h6>
                            <Form>
                                <Row className='items-center mt-3'>
                                    <Col sm={3}>
                                        <Form.Label className='font-Prompt text-[#000000] font-normal text-sm mb-0'>Your Name</Form.Label>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Control className='font-Prompt !font-normal !text-sm !text-[#737373] bg-transparent !border-[#5E8B3B] focus:!shadow-none' type="text" />
                                    </Col>
                                </Row>
                                <Row className='items-center mt-3'>
                                    <Col sm={3}>
                                        <Form.Label className='font-Prompt text-[#000000] font-normal text-sm mb-0'>Resume.CV*</Form.Label>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Group className="relative">
                                            <Form.Label htmlFor="license-file" className='absolute right-[4px] top-[10px] mb-0 cursor-pointer'>
                                                <Image className="w-[25px] h-[16px] object-contain" src={Upload} />
                                            </Form.Label>
                                            <Form.Control
                                                className="d-none font-Prompt !font-normal !text-sm !text-[#737373] bg-transparent !border-[#5E8B3B] focus:!shadow-none"
                                                type="file"
                                                id="license-file"
                                            />
                                            <Form.Control
                                                className="font-Prompt !font-normal !text-sm !text-[#737373] bg-transparent !border-[#5E8B3B] focus:!shadow-none !pr-9"
                                                type="name"
                                                id="license-name"
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='items-center mt-3'>
                                    <Col sm={3}>
                                        <Form.Label className='font-Prompt text-[#000000] font-normal text-sm mb-0'>Qualification</Form.Label>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Control className='font-Prompt !font-normal !text-sm !text-[#737373] bg-transparent !border-[#5E8B3B] focus:!shadow-none' type="text" />
                                    </Col>
                                </Row>
                                <Row className='items-center mt-3'>
                                    <Col sm={3}>
                                        <Form.Label className='font-Prompt text-[#000000] font-normal text-sm mb-0'>Phone number</Form.Label>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Control className='font-Prompt !font-normal !text-sm !text-[#737373] bg-transparent !border-[#5E8B3B] focus:!shadow-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none' type="number" />
                                    </Col>
                                </Row>
                                <Row className='items-center mt-3'>
                                    <Col sm={3}>
                                        <Form.Label className='font-Prompt text-[#000000] font-normal text-sm mb-0'>E-mail Address</Form.Label>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Control className='bg-transparent !border-[#5E8B3B] focus:!shadow-none font-Prompt !font-normal !text-sm !text-[#737373]' type="email" />
                                    </Col>
                                </Row>
                                <Row className='items-center mt-3'>
                                    <Col sm={3}>
                                        <div class="flex items-center">
                                            <Form.Check className='bg-transparent !border-[#5E8B3B] focus:!shadow-none' type="checkbox" />
                                            <Form.Label className='font-Prompt !font-normal text-sm mb-0 mt-[2px] ml-2.5 text-[#000000]'>Bilingual</Form.Label>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Select className='bg-transparent !border-[#5E8B3B] focus:!shadow-none !text-[#737373] font-Prompt !font-normal !text-sm' aria-label="Default select example">
                                            <option value="1">Level</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={9} className='text-end mt-5'>
                                        <div>
                                            <Button className='!bg-[#4379EE] border-0 !py-[5px] !px-[36px] !rounded-sm !font-Prompt !text-sm'>Submit</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Jobdetails