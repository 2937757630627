import React from 'react'
import { Col, Container, Image, ListGroup, Row } from 'react-bootstrap'
import { Cloud, Consulting, MobApp, Sap, ServiceBg, ServiceCloud, ServiceColor, ServiceConsult, ServiceLeft, ServiceMob, ServiceSap, ServiceTech, ServiceWeb, Services, Tech, TextImg, Wave, WebDev } from '../../resources/images'
import { Slide, Fade } from 'react-awesome-reveal';

const Service = () => {
    return (
        <>
            <Container fluid='fluid' className='bg-[#865739] relative mt-[47px]'>
                <Row>
                    <Col md={6}>
                        <div className='p-3'>
                            <Slide direction='down' triggerOnce>
                                <p className='mb-0 text-[#ffff] font-Syne font-normal pt-[3rem] text-lg xsm:!pt-0 sm:!pt-0'>Service</p><hr className='text-[#ffff] opacity-100'></hr>
                            </Slide>
                            <Slide direction='right' triggerOnce>
                                <h4 className='font-Syne font-bold pt-8 text-[#ffff] xsm:pt-4 sm:pt-4'>Service that Matters</h4>
                                <p className='mb-0 font-Syne text-[#ffff]'>How we can Help you</p>
                            </Slide>
                        </div>
                    </Col>
                    <Col className='!pr-0 xsm:hidden sm:hidden' md={6}>
                        <Slide direction='left' triggerOnce>
                            <Image className='h-[350px] w-full' src={Services} />
                        </Slide>
                    </Col>
                </Row>
                <Image className='h-[60px] absolute left-0 -bottom-6 w-full xsm:hidden sm:hidden' src={Wave} />
            </Container>
            <div className='bg-[#fbf5fc] mt-8 py-4 xsm:mt-4 sm:mt-4'>
                <Container>
                    <div className=''>
                        <Slide direction='up' triggerOnce>
                            <h4 className='font-JosefinSans text-center text-[#000000]'>Our Service</h4>
                        </Slide>
                        <Slide direction='down' triggerOnce>
                            <p className='font-Syne text-center text-[#737373] pt-6 xsm:pt-2 sm:pt-2'>FujiSakura services and strategies. IT support and management. We are specialists of all stripes, working offshore or onsite. From website and mobile application development to Quality assurance and testing, a comprehensive set of solutions for all things of IT.</p>
                        </Slide>
                        {/* <div className='relative'>
                            <Image className='w-full h-full' src={ServiceColor} />
                            <div className='absolute top-1/2 left-1/2 w-[80%] -translate-y-2/4 -translate-x-2/4'>
                                <Row>
                                    <Col md={6} className='my-auto'>
                                        <div className='w-[300px]'>
                                            <p className='mb-0 text-[#FEFEFF] text-xs text-left font-Prompt'>What actually we do?</p>
                                            <h1 className='font-Syne text-left text-[#FFFFFF] font-semibold'>Powerful web solutions</h1>
                                            <p className='text-[#C9D8F7] text-sm text-left font-Prompt'>Having an incredible team with over 5 years of experience in the industry allows us to take on unique projects with any complexity and create fascinating, high-performing, and visually outstanding solutions.</p>
                                            <p className='mb-0 text-[#C9D8F7] text-sm text-left font-Prompt'>Our products help clients attract and connect with their target audience and convert them into paying customers.</p>
                                        </div>
                                    </Col>
                                    <Col md={6} className='my-auto'>
                                        <Image src={ServiceLeft} />
                                    </Col>
                                </Row>
                            </div>
                        </div> */}
                        {/* <div className='relative'>
                            <Image src={ServiceBg} />
                            <div className='absolute top-1/2 left-1/4 -translate-y-2/4 -translate-x-2/4 w-[300px]'>
                                <p className='mb-0 text-[#FEFEFF] text-xs text-left font-Prompt'>What actually we do?</p>
                                <h1 className='font-Syne text-left text-[#FFFFFF] font-semibold'>Powerful web solutions</h1>
                                <p className='text-[#C9D8F7] text-sm text-left font-Prompt'>Having an incredible team with over 5 years of experience in the industry allows us to take on unique projects with any complexity and create fascinating, high-performing, and visually outstanding solutions.</p>
                                <p className='mb-0 text-[#C9D8F7] text-sm text-left font-Prompt'>Our products help clients attract and connect with their target audience and convert them into paying customers.</p>
                            </div>
                        </div> */}
                        <div className='hidden xsm:block sm:block'>
                            <Fade cascade damping={0.1} triggerOnce>
                                <h4 className='font-JosefinSans text-center text-[#000000]'>What actually we do?</h4>
                                <p className='font-Syne text-center text-[#737373] font-semibold'>Powerful web solutions</p>
                                <p className='text-[#737373] text-sm text-center font-Prompt'>Having an incredible team with over 5 years of experience in the industry allows us to take on unique projects with any complexity and create fascinating, high-performing, and visually outstanding solutions.</p>
                                <p className='mb-0 text-[#737373] text-sm text-center font-Prompt'>Our products help clients attract and connect with their target audience and convert them into paying customers.</p>
                            </Fade>
                        </div>
                        <Slide direction='up' triggerOnce>
                            <Image className='xsm:hidden sm:hidden' src={TextImg} />
                        </Slide>
                    </div>
                    <div className='mt-24 xsm:mt-12 sm:mt-12'>
                        <Row>
                            <Col md={4}>
                                <div>
                                    <Image className='mb-3 w-[55px] h-[55px]' src={ServiceWeb} />
                                    <h5 className='font-JosefinSans font-semibold text-[#282829]'>Web Development</h5>
                                    <p className='font-Syne text-[#737373]'>We aim to offer creative and user friendly web design and development services. We cover all web and mobile services like web design, mobile application design</p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div>
                                    <Image className='mb-3 w-[55px] h-[55px]' src={ServiceSap} />
                                    <h5 className='font-JosefinSans font-semibold text-[#282829]'>SAP Solutions and Services</h5>
                                    <p className='font-Syne text-[#737373]'>We serve clients at every level of their organization, in whatever capacity, we can be most useful, whether as an expert SAP ABAP advisor to your IT team</p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div>
                                    <Image className='mb-3 w-[55px] h-[55px]' src={ServiceTech} />
                                    <h5 className='font-JosefinSans font-semibold text-[#282829]'>Technology Operation</h5>
                                    <p className='font-Syne text-[#737373]'>Our Technology Operations can help boost brand identity through interactive consumer chats, phone support and seamless remote technical help.FujiSakura is trusted</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className='!mt-10'>
                            <Col md={4}>
                                <div>
                                    <Image className='mb-3 w-[55px] h-[55px]' src={ServiceMob} />
                                    <h5 className='font-JosefinSans font-semibold text-[#282829]'>Mobile App and Development</h5>
                                    <p className='font-Syne text-[#737373]'>We have successfully integrated mobility services with business processes to deliver easy-to-use mobile solutions. Being a reputed mobile application development firm.</p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div>
                                    <Image className='mb-3 w-[55px] h-[55px]' src={ServiceCloud} />
                                    <h5 className='font-JosefinSans font-semibold text-[#282829]'>Cloud and Mobility</h5>
                                    <p className='font-Syne text-[#737373]'>FujiSakura delivers the finest mobility solutions which streamline your operational abilities, thereby saving time and improving the productivity of the workforce.</p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div>
                                    <Image className='mb-3 w-[55px] h-[55px]' src={ServiceConsult} />
                                    <h5 className='font-JosefinSans font-semibold text-[#282829]'>Consulting and Outsourcing</h5>
                                    <p className='font-Syne text-[#737373]'>Japanese Companies are looking at hiring talent from India, knowing the versatility and professional competence of Indian talent that has been demonstrated and proven,</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <Container className='mt-16 mb-16 xsm:mt-8 sm:mt-8'>
                <Row>
                    <Col md={6} className='xsm:mb-8 sm:mb-8'>
                        <Slide direction='left' triggerOnce>
                            <Image className='w-[400px] mx-auto' src={WebDev} />
                        </Slide>
                    </Col>
                    <Col md={6}>
                        <Slide direction='right' triggerOnce>
                            <div>
                                <h6 className='text-[#4379EE] font-JosefinSans'>Web Development</h6>
                                {/* <h4 className='text-[#333333] font-black font-JosefinSans'>Responsive, Fast and modern websites</h4> */}
                                <ListGroup>
                                    <Fade cascade damping={0.1} triggerOnce>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>We aim to offer creative and user friendly web design and development services.</ListGroup.Item>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>We cover all web and mobile services like web design, mobile application design, responsive web design, website conversion, optimization, UI design, UX design and all other creative web based services.</ListGroup.Item>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>We offer web and mobile services using the best in-class development platforms .NET, PHP, Python, JAVA and Front-end technologies.</ListGroup.Item>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>Build and maintain a strong online and digital presence with complete end-to-end digital, web and mobile services offered by FujiSakura.</ListGroup.Item>
                                    </Fade>
                                </ListGroup>
                            </div>
                        </Slide>
                    </Col>
                </Row>
                <Row className='!mt-16 xsm:!mt-8 sm:!mt-8 xsm:flex-col-reverse sm:flex-col-reverse'>
                    <Col md={6}>
                        <Slide direction='left' triggerOnce>
                            <div>
                                <h6 className='text-[#4379EE] font-JosefinSans'>SAP Solutions and Services</h6>
                                {/* <h4 className='text-[#333333] font-black font-JosefinSans'>Responsive, Fast and modern websites</h4> */}
                                <ListGroup>
                                    <Fade cascade damping={0.1} triggerOnce>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>We serve clients at every level of their organization, in whatever capacity, we can be most useful, whether as an expert SAP ABAP advisor to your IT team or a hands-on SAP technical expert for various SAP initiatives that you’ve planned.</ListGroup.Item>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>SAP technical support services to provide expert advice directly to clients on existing SAP landscape.</ListGroup.Item>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>Our SAP technical consultants readily available to work in all regions and industries to help them correct their technical gaps.</ListGroup.Item>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>Deep expertise in a wide range of technologies( SAP ABAP, SAP HANA, SAPUI5, SAP Ariba, SAP Fiori,OData Integration).</ListGroup.Item>
                                    </Fade>
                                </ListGroup>
                            </div>
                        </Slide>
                    </Col>
                    <Col md={6} className='xsm:mb-8 sm:mb-8'>
                        <Slide direction='right' triggerOnce>
                            <Image className='w-[400px] mx-auto' src={Sap} />
                        </Slide>
                    </Col>
                </Row>
                <Row className='!mt-16 xsm:!mt-8 sm:!mt-8'>
                    <Col md={6} className='xsm:mb-8 sm:mb-8'>
                        <Slide direction='left' triggerOnce>
                            <Image className='w-[400px] mx-auto' src={Tech} />
                        </Slide>
                    </Col>
                    <Col md={6}>
                        <Slide direction='right' triggerOnce>
                            <div>
                                <h6 className='text-[#4379EE] font-JosefinSans'>Technology Operation</h6>
                                {/* <h4 className='text-[#333333] font-black font-JosefinSans'>Responsive, Fast and modern websites</h4> */}
                                <ListGroup>
                                    <Fade cascade damping={0.1} triggerOnce>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>Our Technology Operations can help boost brand identity through interactive consumer chats, phone support and seamless remote technical help.</ListGroup.Item>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>FujiSakura is trusted to deliver secure, reliable, consistent 24/7 Technical Support services. Our team of highly-skilled bilingual professionals will collaborate with you to provide technical solutions and guide your customers.</ListGroup.Item>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>What You Get with FujiSakura’s Support and Services.</ListGroup.Item>
                                    </Fade>
                                </ListGroup>
                            </div>
                        </Slide>
                    </Col>
                </Row>
                <Row className='!mt-16 xsm:!mt-8 sm:!mt-8 xsm:flex-col-reverse sm:flex-col-reverse'>
                    <Col md={6}>
                        <Slide direction='left' triggerOnce>
                            <div>
                                <h6 className='text-[#4379EE] font-JosefinSans'>Mobile Application</h6>
                                {/* <h4 className='text-[#333333] font-black font-JosefinSans'>Make your mark with a killer mobile app</h4> */}
                                <ListGroup>
                                    <Fade cascade damping={0.1} triggerOnce>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>We have successfully integrated mobility services with business processes to deliver easy-to-use mobile solutions. Being a reputed mobile application development firm, our independent technology designs ensure users stay connected on any device</ListGroup.Item>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>we understand the importance of UI Design and give priority to Customized UI design for mobile applications</ListGroup.Item>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>With our mobile application development services, we focus on mobile app development cost effectiveness helping you maintain a mobile enterprise presence that drives your organization’s growth.</ListGroup.Item>
                                    </Fade>
                                </ListGroup>
                            </div>
                        </Slide>
                    </Col>
                    <Col md={6} className='xsm:mb-8 sm:mb-8'>
                        <Slide direction='right' triggerOnce>
                            <Image className='w-[400px] mx-auto' src={MobApp} />
                        </Slide>
                    </Col>
                </Row>
                <Row className='!mt-16 xsm:!mt-8 sm:!mt-8'>
                    <Col md={6} className='xsm:mb-8 sm:mb-8'>
                        <Slide direction='left' triggerOnce>
                            <Image className='w-[400px] mx-auto' src={Cloud} />
                        </Slide>
                    </Col>
                    <Col md={6}>
                        <Slide direction='right' triggerOnce>
                            <div>
                                <h6 className='text-[#4379EE] font-JosefinSans'>Cloud and mobility</h6>
                                {/* <h4 className='text-[#333333] font-black font-JosefinSans'>Make your mark with a killer mobile app</h4> */}
                                <ListGroup>
                                    <Fade cascade damping={0.1} triggerOnce>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>FujiSakura delivers the finest mobility solutions which streamline your operational abilities, thereby saving time and improving the productivity of the workforce.</ListGroup.Item>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>FujiSakura’s mobility solutions also empower the organizations to provide highly connected customer experiences.</ListGroup.Item>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>Cloud based applications guarantee consistent and dependable access to the data whenever required.</ListGroup.Item>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>We specialize in development of cloud applications focusing on Microsoft Azure, Amazon and Google cloud platforms.</ListGroup.Item>
                                    </Fade>
                                </ListGroup>
                            </div>
                        </Slide>
                    </Col>
                </Row>
                <Row className='!mt-16 xsm:!mt-8 sm:!mt-8 xsm:flex-col-reverse sm:flex-col-reverse'>
                    <Col md={6}>
                        <Slide direction='left' triggerOnce>
                            <div>
                                <h6 className='text-[#4379EE] font-JosefinSans'>Consulting and Outsourcing</h6>
                                {/* <h4 className='text-[#333333] font-black font-JosefinSans'>Make your mark with a killer mobile app</h4> */}
                                <ListGroup>
                                    <Fade cascade damping={0.1} triggerOnce>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>Japanese Companies are looking at hiring talent from India, knowing the versatility and professional competence of Indian talent that has been demonstrated and proven, beyond doubt in the recent years.</ListGroup.Item>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>With our research driven methodology, strong linkages within the Indo-Japan talent pool and our sourcing skills, we would be in a position to add significant value to your hiring process.</ListGroup.Item>
                                        <ListGroup.Item className='border-0 !text-[#A6A6A7] text-sm font-Prompt relative before:absolute before:bg-list-pattern before:w-[14px] before:h-[11px] before:bg-no-repeat before:left-[-3px] before:top-[13px] before:bg-contain'>Domain knowledge, multi -tasking, ability to adapt and experience of having worked in a complex Indian market are the factors that prompt global organizations to look for Indian talent.</ListGroup.Item>
                                    </Fade>
                                </ListGroup>
                            </div>
                        </Slide>
                    </Col>
                    <Col md={6} className='xsm:mb-8 sm:mb-8'>
                        <Slide direction='right' triggerOnce>
                            <Image className='w-[400px] mx-auto' src={Consulting} />
                        </Slide>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Service