import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Bg, Carrers, JoinTeam, Wave } from '../../resources/images'
import Applyjob from '../Job/Applyjob'
import { Slide } from 'react-awesome-reveal';

const Carrer = () => {
    return (
        <>
            <Container fluid='fluid' className='bg-[#71503F] relative mt-[47px]'>
                <Row>
                    <Col md={6}>
                        <div className='p-3'>
                            <Slide direction='down' triggerOnce>
                                <p className='mb-0 text-[#ffff] font-Syne font-normal pt-[3rem] text-lg xsm:!pt-0 sm:!pt-0'>Carrer</p><hr className='text-[#ffff] opacity-100'></hr>
                            </Slide>
                            <Slide direction='right' triggerOnce>
                                <h4 className='font-Syne font-bold pt-8 text-[#ffff] xsm:pt-4 sm:pt-4'>Boost Your Career With Us!</h4>
                                <p className='mb-0 font-Syne text-[#ffff]'>We're always looking for the talents and professionals to join our winning team.</p>
                            </Slide>
                        </div>
                    </Col>
                    <Col className='!pr-0 xsm:hidden sm:hidden' md={6}>
                        <Slide direction='left' triggerOnce>
                            <Image className='h-[350px] w-full' src={Carrers} />
                        </Slide>
                    </Col>
                </Row>
                <Image className='h-[60px] absolute left-0 -bottom-6 w-full xsm:hidden sm:hidden' src={Wave} />
            </Container>
            <Container className='mb-3'>
                <div>
                    <Image src={JoinTeam} />
                </div>
                <div>
                    <Slide direction='up' triggerOnce>
                        <h5 className='font-Prompt text-center text-[#000000] mb-3'>Ready for your next adventure with FujiSakura?</h5>
                    </Slide>
                    <Slide direction='down' triggerOnce>
                        <p className='mb-0 font-Prompt text-center text-[#737373]'>If you have the right attitude and skills to nurture, we offer you the perfect</p>
                        <p className='mb-0 font-Prompt text-center text-[#737373]'>infrastructure to grow – both professionally and personally.</p>
                    </Slide>
                </div>
                <Applyjob />
            </Container>
        </>
    )
}

export default Carrer