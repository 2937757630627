import React from 'react'
import { Col, Container, Image, Nav, Row } from 'react-bootstrap'
import { FujiLogo, Iso1, Iso2, Nasscom } from '../../resources/images'
import { NavLink } from 'react-router-dom'
import privacy from "../Privacy/Privacy.pdf"
import Company from "../Companypolicy/companypolicy.pdf"

const Footer = () => {
    return (
        <div className='pt-4 pb-3 bg-[#E8EDF9] mt-3'>
            <Container>
                <Row>
                    <Col md={3}>
                        <div>
                            <h6 className='font-Prompt text-[#000000] text-sm'>Quick Link</h6>
                            <Nav className='d-block'>
                                <Nav.Item className='py-2'>
                                    <NavLink className="!font-Prompt text-[#737373] text-sm no-underline hover:ml-2.5 duration-500 md:text-xs" to={"/"}>
                                        Home
                                    </NavLink>
                                </Nav.Item>
                                <Nav.Item className='py-2'>
                                    <NavLink className="!font-Prompt text-[#737373] text-sm no-underline hover:ml-2.5 duration-500 md:text-xs" to={"/service"}>
                                        Services
                                    </NavLink>
                                </Nav.Item>
                                <Nav.Item className='py-2'>
                                    <NavLink className="!font-Prompt text-[#737373] text-sm no-underline hover:ml-2.5 duration-500 md:text-xs" to={"/carrer"}>
                                        Career
                                    </NavLink>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div>
                            <h6 className='font-Prompt text-[#000000] text-sm'>About Us</h6>
                            <Nav className='d-block'>
                                <Nav.Item className='py-2'>
                                    <NavLink className="!font-Prompt text-[#737373] text-sm no-underline hover:ml-2.5 duration-500 md:text-xs" to={"/company"}>
                                        Corporate Information
                                    </NavLink>
                                </Nav.Item>
                                <Nav.Item className='py-2'>
                                    <NavLink className="!font-Prompt text-[#737373] text-sm no-underline hover:ml-2.5 duration-500 md:text-xs" to={"/director"}>
                                        Leadership
                                    </NavLink>
                                </Nav.Item>
                                <Nav.Item className='py-2'>
                                    <NavLink className="!font-Prompt text-[#737373] text-sm no-underline hover:ml-2.5 duration-500 md:text-xs" to={"/contact"}>
                                        Contact
                                    </NavLink>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div>
                            <h6 className='font-Prompt text-[#000000] text-sm'>Location</h6>
                            <p className='font-Prompt text-[#737373] text-sm md:text-xs'>Plot No. 22 & 23,1st Floor (1A & 1B),Sri Durga Enclave - Phase II,River View Residency 2nd Street,Karapakkam,Chennai - 600 097</p>
                        </div>
                        <div>
                            <h6 className='font-Prompt text-[#000000] text-sm'>Call us & Hire us</h6>
                            <a href="tel: +91 44 2952 0058" className='font-Prompt text-[#737373] text-sm mb-1 no-underline md:text-xs'>+91 44 2952 0058</a>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className='text-center'>
                            <NavLink to={"/"}>
                                <Image className='w-36 h-9 mb-3 mr-auto ml-auto xsm:m-0 sm:m-0' src={FujiLogo} />
                            </NavLink>
                            <p className='font-Prompt text-[#737373] text-sm md:text-xs xsm:!text-left  sm:!text-left'>Accredited by UQSR</p>
                            <div className='flex items-center gap-x-3 mb-3 justify-center  xsm:justify-normal sm:justify-normal'>
                                <Image className='w-[80px]' src={Iso1} />
                                <Image className='w-[80px]' src={Iso2} />
                            </div>
                            <p className='font-Prompt text-[#737373] text-sm md:text-xs xsm:!text-left  sm:!text-left'>Member of</p>
                            <Image className='w-[120px] mr-auto ml-auto xsm:m-0 sm:m-0' src={Nasscom} />
                        </div>
                    </Col>
                </Row><hr></hr>
                <div className='text-center mt-3'>
                    <p className='font-Prompt text-[#7B7B7B] text-sm mb-0'>Copyright ©2023 All rights reserved | FujiSakura Technologies PVT LTD.<span className='ml-3'><a className='font-Prompt text-[#737373] text-sm mb-1 decoration-[#737373]' target='_blank' href={privacy}> Privacy Policy</a></span> | <span><a target='_blank' href={Company} className='font-Prompt text-[#737373] text-sm mb-1 decoration-[#737373]'>Company Policy</a></span></p>
                </div>
            </Container>
        </div>
    )
}

export default Footer