import { useState } from "react";
// import ReactPageScroller from "react-page-scroller";
import { Carousel } from 'react-responsive-carousel';
import { BulbGlb, Creative, Innovation, MarketGlb, Marketing, PuzzelGlb, Quality, QualityGlb, Strategy, StrategyGlb } from '../../resources/images';
import "@google/model-viewer/dist/model-viewer";
import { Image, Nav, Tab, Tabs } from 'react-bootstrap';

const Slidertab = () => {
    const [blockScroll, setBlockScroll] = useState(false)

    return (
        <>
            {/* <div className="w-full h-screen one">
                <ReactPageScroller
                    className="w-full h-screen overflow-hidden"
                    onBeforePageScroll={() => setBlockScroll(true)}
                    pageOnChange={() => {
                        const timeout = setTimeout(() => {
                            setBlockScroll(false)
                            clearTimeout(timeout)
                        }, 750)
                    }} >
                    <section className='bg-[#A5E6EC] w-full h-screen relative'>
                        <div className='absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4'>
                            <p className='font-Prompt text-base text-center'>What makes us stand alone</p>
                            <Nav className="me-auto pt-5 pb-5 justify-content-center">
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg !underline'>Innovative</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Creative</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Quality</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Strategy</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Marketing</Nav.Link>
                            </Nav>
                            <model-viewer
                                src={PuzzelGlb}
                                class="my-0 mx-auto"
                                auto-rotate
                                ar
                                camera-controls></model-viewer>
                            <div className='pt-5'>
                                <Image className='w-[600px] my-0 mx-auto' src={Innovation} />
                            </div>
                            <div className='pt-5'>
                                <p className='mb-0 text-[#282829] text-base text-center'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
                                <p className='mb-0 text-[#282829] text-base text-center'>Velit officia consequat duis enim velit mollit.</p>
                            </div>
                        </div>
                    </section>
                    <section className='bg-[#A5CAEC] w-full h-screen relative'>
                        <div className='absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4'>
                            <p className='font-Prompt text-base text-center'>What makes us stand alone</p>
                            <Nav className="me-auto pt-5 pb-5 justify-content-center">
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Innovative</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg !underline'>Creative</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Quality</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Strategy</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Marketing</Nav.Link>
                            </Nav>
                            <model-viewer
                                src={BulbGlb}
                                class="my-0 mx-auto"
                                auto-rotate
                                ar
                                camera-controls></model-viewer>
                            <div className='pt-5'>
                                <Image className='w-[600px] my-0 mx-auto' src={Creative} />
                            </div>
                            <div className='pt-5'>
                                <p className='mb-0 text-[#282829] text-base text-center'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
                                <p className='mb-0 text-[#282829] text-base text-center'>Velit officia consequat duis enim velit mollit.</p>
                            </div>
                        </div>
                    </section>
                    <section className='bg-[#F0E2A7] w-full h-screen relative'>
                        <div className='absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4'>
                            <p className='font-Prompt text-base text-center'>What makes us stand alone</p>
                            <Nav className="me-auto pt-5 pb-5 justify-content-center">
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Innovative</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Creative</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg !underline'>Quality</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Strategy</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Marketing</Nav.Link>
                            </Nav>
                            <model-viewer
                                src={QualityGlb}
                                class="my-0 mx-auto"
                                auto-rotate
                                ar
                                camera-controls></model-viewer>
                            <div className='pt-5'>
                                <Image className='w-[600px] my-0 mx-auto' src={Quality} />
                            </div>
                            <div className='pt-5'>
                                <p className='mb-0 text-[#282829] text-base text-center'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
                                <p className='mb-0 text-[#282829] text-base text-center'>Velit officia consequat duis enim velit mollit.</p>
                            </div>
                        </div>
                    </section>
                    <section className='bg-[#E4C4C4] w-full h-screen relative'>
                        <div className='absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4'>
                            <p className='font-Prompt text-base text-center'>What makes us stand alone</p>
                            <Nav className="me-auto pt-5 pb-5 justify-content-center">
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Innovative</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Creative</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Quality</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg !underline'>Strategy</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Marketing</Nav.Link>
                            </Nav>
                            <model-viewer
                                src={StrategyGlb}
                                class="my-0 mx-auto"
                                auto-rotate
                                ar
                                camera-controls></model-viewer>
                            <div className='pt-5'>
                                <Image className='w-[600px] my-0 mx-auto' src={Strategy} />
                            </div>
                            <div className='pt-5'>
                                <p className='mb-0 text-[#282829] text-base text-center'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
                                <p className='mb-0 text-[#282829] text-base text-center'>Velit officia consequat duis enim velit mollit.</p>
                            </div>
                        </div>
                    </section>
                    <section className='bg-[#A5ECA8] w-full h-screen relative'>
                        <div className='absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4'>
                            <p className='font-Prompt text-base text-center'>What makes us stand alone</p>
                            <Nav className="me-auto pt-5 pb-5 justify-content-center">
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Innovative</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Creative</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Quality</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Strategy</Nav.Link>
                                <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg !underline'>Marketing</Nav.Link>
                            </Nav>
                            <model-viewer
                                src={MarketGlb}
                                class="my-0 mx-auto"
                                auto-rotate
                                ar
                                camera-controls></model-viewer>
                            <div className='pt-5'>
                                <Image className='w-[600px] my-0 mx-auto' src={Marketing} />
                            </div>
                            <div className='pt-5'>
                                <p className='mb-0 text-[#282829] text-base text-center'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
                                <p className='mb-0 text-[#282829] text-base text-center'>Velit officia consequat duis enim velit mollit.</p>
                            </div>
                        </div>
                    </section>
                </ReactPageScroller>
            </div> */}
            {/* <Carousel className="mt-[47px]" showThumbs={false} CarouselStatus={false} showIndicators={false} showStatus={false} autoPlay infiniteLoop={true} interval="4000" transitionTime="1000">
             <section className='bg-[#A5E6EC] w-full h-screen relative'>
                 <div className='absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4'>
                     <p className='font-Prompt text-base text-center'>What makes us stand alone</p>
                     <Nav className="me-auto pt-5 pb-5 justify-content-center">
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg !underline'>Innovative</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Creative</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Quality</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Strategy</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Marketing</Nav.Link>
                     </Nav>
                     <model-viewer 
                         src={PuzzelGlb}
                         class="my-0 mx-auto"
                         auto-rotate
                         ar
                         camera-controls></model-viewer>
                     <div className='pt-5'>
                         <Image className='w-[600px] my-0 mx-auto' src={Innovation} />
                     </div>
                     <div className='pt-5'>
                         <p className='mb-0 text-[#282829] text-base text-center'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
                         <p className='mb-0 text-[#282829] text-base text-center'>Velit officia consequat duis enim velit mollit.</p>
                     </div>
                 </div>
             </section>
             <section className='bg-[#A5CAEC] w-full h-screen relative'>
                 <div className='absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4'>
                     <p className='font-Prompt text-base text-center'>What makes us stand alone</p>
                     <Nav className="me-auto pt-5 pb-5 justify-content-center">
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Innovative</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg !underline'>Creative</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Quality</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Strategy</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Marketing</Nav.Link>
                     </Nav>
                     <model-viewer
                         src={BulbGlb}
                         class="my-0 mx-auto"
                         auto-rotate
                         ar
                         camera-controls></model-viewer>
                     <div className='pt-5'>
                         <Image className='w-[600px] my-0 mx-auto' src={Creative} />
                     </div>
                     <div className='pt-5'>
                         <p className='mb-0 text-[#282829] text-base text-center'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
                         <p className='mb-0 text-[#282829] text-base text-center'>Velit officia consequat duis enim velit mollit.</p>
                     </div>
                 </div>
             </section >
             <section className='bg-[#F0E2A7] w-full h-screen relative'>
                 <div className='absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4'>
                     <p className='font-Prompt text-base text-center'>What makes us stand alone</p>
                     <Nav className="me-auto pt-5 pb-5 justify-content-center">
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Innovative</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Creative</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg !underline'>Quality</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Strategy</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Marketing</Nav.Link>
                     </Nav>
                     <model-viewer
                         src={QualityGlb}
                         class="my-0 mx-auto"
                         auto-rotate
                         ar
                         camera-controls></model-viewer>
                     <div className='pt-5'>
                         <Image className='w-[600px] my-0 mx-auto' src={Quality} />
                     </div>
                     <div className='pt-5'>
                         <p className='mb-0 text-[#282829] text-base text-center'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
                         <p className='mb-0 text-[#282829] text-base text-center'>Velit officia consequat duis enim velit mollit.</p>
                     </div>
                 </div>
             </section>
             <section className='bg-[#E4C4C4] w-full h-screen relative'>
                 <div className='absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4'>
                     <p className='font-Prompt text-base text-center'>What makes us stand alone</p>
                     <Nav className="me-auto pt-5 pb-5 justify-content-center">
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Innovative</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Creative</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Quality</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg !underline'>Strategy</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Marketing</Nav.Link>
                     </Nav>
                     <model-viewer
                         src={StrategyGlb}
                         class="my-0 mx-auto"
                         auto-rotate
                         ar
                         camera-controls></model-viewer>
                     <div className='pt-5'>
                         <Image className='w-[600px] my-0 mx-auto' src={Strategy} />
                     </div>
                     <div className='pt-5'>
                         <p className='mb-0 text-[#282829] text-base text-center'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
                         <p className='mb-0 text-[#282829] text-base text-center'>Velit officia consequat duis enim velit mollit.</p>
                     </div>
                 </div>
             </section>
             <section className='bg-[#A5ECA8] w-full h-screen relative'>
                 <div className='absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4'>
                     <p className='font-Prompt text-base text-center'>What makes us stand alone</p>
                     <Nav className="me-auto pt-5 pb-5 justify-content-center">
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Innovative</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Creative</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Quality</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg'>Strategy</Nav.Link>
                         <Nav.Link className='!text-[#282829] !font-JosefinSans !text-lg !underline'>Marketing</Nav.Link>
                     </Nav>
                     <model-viewer
                         src={MarketGlb}
                         class="my-0 mx-auto"
                         auto-rotate
                         ar
                         camera-controls></model-viewer>
                     <div className='pt-5'>
                         <Image className='w-[600px] my-0 mx-auto' src={Marketing} />
                     </div>
                     <div className='pt-5'>
                         <p className='mb-0 text-[#282829] text-base text-center'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
                         <p className='mb-0 text-[#282829] text-base text-center'>Velit officia consequat duis enim velit mollit.</p>
                     </div>
                 </div>
             </section>
            </Carousel > */}
            <section className='bg-[#A5E6EC] w-full h-screen flex items-center justify-center'>
                <div>
                    <p className='font-Prompt text-base text-center'>What makes us stand alone</p>
                    <Tabs
                        defaultActiveKey="innovative"
                        id="uncontrolled-tab-example"
                        className="mb-3 justify-content-center model-tab border-0"
                    >
                        <Tab className='mt-16 xsm:mt-8' eventKey="innovative" title="Innovative">
                            <model-viewer
                                src={PuzzelGlb}
                                class="my-0 mx-auto"
                                auto-rotate
                                ar
                                camera-controls></model-viewer>
                            <div className='pt-5 xsm:px-[5px]'>
                                <Image className='w-[600px] my-0 mx-auto' src={Innovation} />
                            </div>
                            <div className='pt-5 xsm:px-[5px]'>
                                <p className='mb-0 text-[#282829] text-base text-center'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
                                <p className='mb-0 text-[#282829] text-base text-center'>Velit officia consequat duis enim velit mollit.</p>
                            </div>
                        </Tab>
                        <Tab className='mt-16 xsm:mt-8' eventKey="creative" title="Creative">
                            <model-viewer
                                src={BulbGlb}
                                class="my-0 mx-auto"
                                auto-rotate
                                ar
                                camera-controls></model-viewer>
                            <div className='pt-5 xsm:px-[5px]'>
                                <Image className='w-[600px] my-0 mx-auto' src={Creative} />
                            </div>
                            <div className='pt-5 xsm:px-[5px]'>
                                <p className='mb-0 text-[#282829] text-base text-center'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
                                <p className='mb-0 text-[#282829] text-base text-center'>Velit officia consequat duis enim velit mollit.</p>
                            </div>
                        </Tab>
                        <Tab className='mt-16 xsm:mt-8' eventKey="quality" title="Quality">

                            <model-viewer
                                src={QualityGlb}
                                class="my-0 mx-auto"
                                auto-rotate
                                ar
                                camera-controls></model-viewer>
                            <div className='pt-5 xsm:px-[5px]'>
                                <Image className='w-[600px] my-0 mx-auto' src={Quality} />
                            </div>
                            <div className='pt-5 xsm:px-[5px]'>
                                <p className='mb-0 text-[#282829] text-base text-center'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
                                <p className='mb-0 text-[#282829] text-base text-center'>Velit officia consequat duis enim velit mollit.</p>
                            </div>
                        </Tab>
                        <Tab className='mt-16 xsm:mt-8' eventKey="strategy" title="Strategy">
                            <model-viewer
                                src={StrategyGlb}
                                class="my-0 mx-auto"
                                auto-rotate
                                ar
                                camera-controls></model-viewer>
                            <div className='pt-5 xsm:px-[5px]'>
                                <Image className='w-[600px] my-0 mx-auto' src={Strategy} />
                            </div>
                            <div className='pt-5 xsm:px-[5px]'>
                                <p className='mb-0 text-[#282829] text-base text-center'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
                                <p className='mb-0 text-[#282829] text-base text-center'>Velit officia consequat duis enim velit mollit.</p>
                            </div>
                        </Tab>
                        <Tab className='mt-16 xsm:mt-8' eventKey="marketing" title="Marketing">
                            <model-viewer
                                src={MarketGlb}
                                class="my-0 mx-auto"
                                auto-rotate
                                ar
                                camera-controls></model-viewer>
                            <div className='pt-5 xsm:px-[5px]'>
                                <Image className='w-[600px] my-0 mx-auto' src={Marketing} />
                            </div>
                            <div className='pt-5 xsm:px-[5px]'>
                                <p className='mb-0 text-[#282829] text-base text-center'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
                                <p className='mb-0 text-[#282829] text-base text-center'>Velit officia consequat duis enim velit mollit.</p>
                            </div>
                        </Tab>
                    </Tabs>

                </div>
            </section>
        </>
    )
}
export default Slidertab