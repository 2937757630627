import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbarlayout from './components/Navbar/Navbarlayout';
import { Route, Routes } from 'react-router-dom';
import Home from "./components/Home/Home";
import Company from './components/Company/Company';
import Service from './components/Service/Service';
import Carrer from './components/Carrer/Carrer';
import Contact from './components/Contact/Contact';
import Jobdetails from './components/Job/Jobdetails';
import Leadership from './components/Company/Leadership';
import ScrollTop from './components/Home/ScrollTop';

function App() {
  return (
    <ScrollTop>
      <Routes>
        <Route element={<Navbarlayout />}>
          <Route index path='/' element={<Home />} />
          <Route path="/company" element={<Company />} />
          <Route path="/service" element={<Service />} />
          <Route path="carrer" element={<Carrer />} />
          <Route path="carrer/:id" element={<Jobdetails />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/director" element={<Leadership />} />
        </Route>
      </Routes>
    </ScrollTop>
  );
}

export default App;
