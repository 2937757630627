import React from 'react'
import { Adobe, Android, Angular, BgVideo1, BgVideo2, BgVideo3, Blender, Bulb, Client, Collaboration, Delivery, Django, Figma, File, Ios, Js, Lap, Laravel, Manage, Mike, Mobile, Msg, Net, Nextjs, Nodejs, Pen, Php, Python, Reactnative, Sketch, SlideLeft, SlideRight, Sound, Springboot, Ts, Vue, Xd } from '../../resources/images/index';
import { Button, Col, Container, Image, Row, Tab, Tabs } from 'react-bootstrap';
// import "@google/model-viewer/dist/model-viewer";
import { Fade, Slide } from "react-awesome-reveal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slidertab from './slidertab';
// import Scroll from "./scroll"

const Home = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />
    };
    return (
        <>
            <div className='mt-[47px]'>
                <Slider {...settings}>
                    <div className="before:content-content-[''] before:absolute before:bg-gradient-to-r before:from-[#000047] before:to-[#000047]/[0.3] before:w-full before:h-[calc(100vh_-_46px)] before:left-0 relative">
                        <video className="w-100 h-[calc(100vh_-_46px)] object-cover" height="auto" autoPlay loop muted>
                            <source
                                src={BgVideo3}
                                type="video/mp4"
                                allowFullScreen
                            />
                        </video>
                        <Slide className='absolute top-[20%] left-24 xsm:left-8 xsm:w-64 w-96 -translate-y-2/4 -translate-x-2/4;' triggerOnce>
                            <div>
                                <h2 className='text-white text-left mb-5 font-Syne'>Unleash the power of Engaged Employees</h2>
                                <h6 className="p-4 text-white text-left font-Prompt font-light mb-5 relative before:content-[''] before:absolute before:bg-white before:w-1 before:h-full before:top-0 before:left-0">We are team of talented Engineers making your ideas come true</h6>
                                <div className='text-left'>
                                    <Button className="!bg-transparent border-1 !border-[#4379EE] px-5 py-2 !text-sm !rounded-sm relative before:content-[''] before:absolute before:block before:box-border before:bg-[#4379EE] before:top-0 before:left-0 before:w-full before:h-full before:mix-blend-lighten before:duration-500 before:ease before:transition before:origin-center before:scale-0	hover:before:scale-100">Our Services</Button>
                                </div><hr className='text-[#4379EE] opacity-100 border-4 mt-5 rounded'></hr>
                            </div>
                        </Slide>
                    </div>
                    <div className="before:content-content-[''] before:absolute before:bg-gradient-to-r before:from-[#000047] before:to-[#000047]/[0.3] before:w-full before:h-[calc(100vh_-_46px)] before:left-0 relative">
                        <video className="w-100 h-[calc(100vh_-_46px)] object-cover" height="auto" autoPlay loop muted>
                            <source
                                src={BgVideo2}
                                type="video/mp4"
                                allowFullScreen
                            />
                        </video>
                        <Slide className='absolute top-[20%] left-24 xsm:left-8 xsm:w-64 w-96 -translate-y-2/4 -translate-x-2/4;' triggerOnce>
                            <div>
                                <h2 className='text-white text-left mb-5 font-Syne'>Unleash the power of Engaged Employees</h2>
                                <h6 className="p-4 text-white text-left font-Prompt font-light mb-5 relative before:content-[''] before:absolute before:bg-white before:w-1 before:h-full before:top-0 before:left-0">We are team of talented Engineers making your ideas come true</h6>
                                <div className='text-left'>
                                    <Button className="!bg-transparent border-1 !border-[#4379EE] px-5 py-2 !text-sm !rounded-sm relative before:content-[''] before:absolute before:block before:box-border before:bg-[#4379EE] before:top-0 before:left-0 before:w-full before:h-full before:mix-blend-lighten before:duration-500 before:ease before:transition before:origin-center before:scale-0	hover:before:scale-100">Our Services</Button>
                                </div><hr className='text-[#4379EE] opacity-100 border-4 mt-5 rounded'></hr>
                            </div>
                        </Slide>
                    </div>
                    <div className="before:content-content-[''] before:absolute before:bg-gradient-to-r before:from-[#000047] before:to-[#000047]/[0.3] before:w-full before:h-[calc(100vh_-_46px)] before:left-0 relative">
                        <video className="w-100 h-[calc(100vh_-_46px)] object-cover" height="auto" autoPlay loop muted>
                            <source
                                src={BgVideo1}
                                type="video/mp4"
                                allowFullScreen
                            />
                        </video>
                        <Slide className='absolute top-[20%] left-24 xsm:left-8 xsm:w-64 w-96 -translate-y-2/4 -translate-x-2/4;' triggerOnce>
                            <div>
                                <h2 className='text-white text-left mb-5 font-Syne'>Unleash the power of Engaged Employees</h2>
                                <h6 className="p-4 text-white text-left font-Prompt font-light mb-5 relative before:content-[''] before:absolute before:bg-white before:w-1 before:h-full before:top-0 before:left-0">We are team of talented Engineers making your ideas come true</h6>
                                <div className='text-left'>
                                    <Button className="!bg-transparent border-1 !border-[#4379EE] px-5 py-2 !text-sm !rounded-sm relative before:content-[''] before:absolute before:block before:box-border before:bg-[#4379EE] before:top-0 before:left-0 before:w-full before:h-full before:mix-blend-lighten before:duration-500 before:ease before:transition before:origin-center before:scale-0	hover:before:scale-100">Our Services</Button>
                                </div><hr className='text-[#4379EE] opacity-100 border-4 mt-5 rounded'></hr>
                            </div>
                        </Slide>
                    </div>
                </Slider>
            </div>
            {/* <Scroll /> */}
            {/* <Sticky /> */}
            <Container className='mt-5'>
                <div className='text-center xsm:my-2'>
                    <Slide direction='right' triggerOnce>
                        <h3 className='text-[#282829] font-Prompt font-bold text-center'>Process We Follow</h3>
                    </Slide>
                    <p className='text-[#737373] mb-0 text-sm font-Prompt'>FujiSakura Technologies is one of the fastest growing companies in Chennai, providing IT solutions and Services to the industry.</p>
                    <p className='text-[#737373] mb-0 text-sm font-Prompt'>At FujiSakura, we work by the principle of offering unmatched and best quality of services to our clients.</p>
                </div>
                <Row className='mt-5'>
                    <Col md={6} sm={6} lg={3}>
                        <Slide direction='up' triggerOnce>
                            <div className='text-center bg-white d-flex align-items-center justify-content-center w-52 h-52 rounded-lg my-3 mx-auto px-8 py-8 shadow-[rgba(136,165,191,0.48)_6px_2px_16px_0px,rgba(255,255,255,0.8)_-6px_-2px_16px_0px]'>
                                <div>
                                    <Image className='w-16 h-16 object-contain my-0 mx-auto pb-3' src={Bulb} />
                                    <p className='text-[#282829] mb-0 text-sm font-semibold mb-2 font-JosefinSans'>IDEA</p>
                                    <p className='text-[#737373] mb-0 font-JosefinSans text-xs'>Thoughts to shapes</p>
                                </div>
                            </div>
                        </Slide>
                    </Col>
                    <Col md={6} sm={6} lg={3}>
                        <Slide direction='up' triggerOnce>
                            <div className='text-center bg-white d-flex align-items-center justify-content-center w-52 h-52 rounded-lg my-3 mx-auto px-8 py-8 shadow-[rgba(136,165,191,0.48)_6px_2px_16px_0px,rgba(255,255,255,0.8)_-6px_-2px_16px_0px]'>
                                <div>
                                    <Image className='w-16 h-16 object-contain my-0 mx-auto pb-3' src={File} />
                                    <p className='text-[#282829] mb-0 text-sm font-semibold mb-2 font-JosefinSans'>Requirement Gathering</p>
                                    <p className='text-[#737373] mb-0 font-JosefinSans text-xs'>Aim to building an application</p>
                                </div>
                            </div>
                        </Slide>
                    </Col>
                    <Col md={6} sm={6} lg={3}>
                        <Slide direction='up' triggerOnce>
                            <div className='text-center bg-white d-flex align-items-center justify-content-center w-52 h-52 rounded-lg my-3 mx-auto px-8 py-8 shadow-[rgba(136,165,191,0.48)_6px_2px_16px_0px,rgba(255,255,255,0.8)_-6px_-2px_16px_0px]'>
                                <div>
                                    <Image className='w-16 h-16 object-contain my-0 mx-auto pb-3' src={Pen} />
                                    <p className='text-[#282829] mb-0 text-sm font-semibold mb-2 font-JosefinSans'>UI/ UX Design</p>
                                    <p className='text-[#737373] mb-0 font-JosefinSans text-xs'>Transparency meet here</p>
                                </div>
                            </div>
                        </Slide>
                    </Col>
                    <Col md={6} sm={6} lg={3}>
                        <Slide direction='up' triggerOnce>
                            <div className='text-center bg-white d-flex align-items-center justify-content-center w-52 h-52 rounded-lg my-3 mx-auto px-8 py-8 shadow-[rgba(136,165,191,0.48)_6px_2px_16px_0px,rgba(255,255,255,0.8)_-6px_-2px_16px_0px]'>
                                <div>
                                    <Image className='w-16 h-16 object-contain my-0 mx-auto pb-3' src={Mobile} />
                                    <p className='text-[#282829] mb-0 text-sm font-semibold mb-2 font-JosefinSans'>PROTOTYPE</p>
                                    <p className='text-[#737373] mb-0 font-JosefinSans text-xs'>Thoughts to shapes</p>
                                </div>
                            </div>
                        </Slide>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col md={6} sm={6} lg={3}>
                        <Slide direction='down' triggerOnce>
                            <div className='text-center bg-white d-flex align-items-center justify-content-center w-52 h-52 rounded-lg my-3 mx-auto px-8 py-8 shadow-[rgba(136,165,191,0.48)_6px_2px_16px_0px,rgba(255,255,255,0.8)_-6px_-2px_16px_0px]'>
                                <div>
                                    <Image className='w-16 h-16 object-contain my-0 mx-auto pb-3' src={Lap} />
                                    <p className='text-[#282829] mb-0 text-sm font-semibold mb-2 font-JosefinSans'>DEVELOPMENT</p>
                                    <p className='text-[#737373] mb-0 font-JosefinSans text-xs'>Develop the process</p>
                                </div>
                            </div>
                        </Slide>
                    </Col>
                    <Col md={6} sm={6} lg={3}>
                        <Slide direction='down' triggerOnce>
                            <div className='text-center bg-white d-flex align-items-center justify-content-center w-52 h-52 rounded-lg my-3 mx-auto px-8 py-8 shadow-[rgba(136,165,191,0.48)_6px_2px_16px_0px,rgba(255,255,255,0.8)_-6px_-2px_16px_0px]'>
                                <div>
                                    <Image className='w-16 h-16 object-contain my-0 mx-auto pb-3' src={Manage} />
                                    <p className='text-[#282829] mb-0 text-sm font-semibold mb-2 font-JosefinSans'>Testing & Deployment</p>
                                    <p className='text-[#737373] mb-0 font-JosefinSans text-xs'>Unboxing the Quality</p>
                                </div>
                            </div>
                        </Slide>
                    </Col>
                    <Col md={6} sm={6} lg={3}>
                        <Slide direction='down' triggerOnce>
                            <div className='text-center bg-white d-flex align-items-center justify-content-center w-52 h-52 rounded-lg my-3 mx-auto px-8 py-8 shadow-[rgba(136,165,191,0.48)_6px_2px_16px_0px,rgba(255,255,255,0.8)_-6px_-2px_16px_0px]'>
                                <div>
                                    <Image className='w-16 h-16 object-contain my-0 mx-auto pb-3' src={Mike} />
                                    <p className='text-[#282829] mb-0 text-sm font-semibold mb-2 font-JosefinSans'>Sup triggerOnceport & Maintenance</p>
                                    <p className='text-[#737373] mb-0 font-JosefinSans text-xs'>Enhancing beyond the sky</p>
                                </div>
                            </div>
                        </Slide>
                    </Col>
                    <Col md={6} sm={6} lg={3}>
                        <Slide direction='down' triggerOnce>
                            <div className='text-center bg-white d-flex align-items-center justify-content-center w-52 h-52 rounded-lg my-3 mx-auto px-8 py-8 shadow-[rgba(136,165,191,0.48)_6px_2px_16px_0px,rgba(255,255,255,0.8)_-6px_-2px_16px_0px]'>
                                <div>
                                    <Image className='w-16 h-16 object-contain my-0 mx-auto pb-3' src={Sound} />
                                    <p className='text-[#282829] mb-0 text-sm font-semibold mb-2 font-JosefinSans'>Marketing & Promotions</p>
                                    <p className='text-[#737373] mb-0 font-JosefinSans text-xs'>Reward for the built application</p>
                                </div>
                            </div>
                        </Slide>
                    </Col>
                </Row>
            </Container>
            <Slidertab />
            <div className='mt-3 bg-[#F6F8FE] px-8 py-16 xsm:py-8 xsm:px-4  sm:py-8 sm:px-4'>
                <Container>
                    <h3 className='text-[#282829] font-Prompt font-bold text-center'>Belief of an every FujiSakurian</h3>
                    <p className='text-[#737373] mb-0 text-sm font-Prompt text-center pt-3'>We provide design and development services for every stage of your mobile project.</p>
                    <Row className="!mt-28 pb-3 relative before:content-content-[''] before:absolute before:border-2 before:border-solid before-[#B5C0D2]/[0.4] before:w-[70%] before:h-[165%] before:rounded-[56%] before:top-[29%] before:left-[50%] before:-translate-y-2/4 before:-translate-x-2/4 xsm:before:content-none xsm:!mt-8 sm:before:content-none sm:!mt-8">
                        <Col className='xsm:!my-4 sm:!my-4' md={4}>
                            <div>
                                <Image className='w-24 mx-auto my-0 drop-shadow-4lg' src={Client} />
                                <p className='text-[#282829] font-Prompt relative pt-10 mb-0 font-semibold text-center xsm:pt-6 sm:pt-6'>Client-centric Approach</p>
                            </div>
                        </Col>
                        <Col className='xsm:!my-4 sm:!my-4' md={4}>
                            <div>
                                <Image className='w-24 mx-auto my-0 drop-shadow-4lg' src={Collaboration} />
                                <p className='text-[#282829] font-Prompt relative pt-10 mb-0 font-semibold text-center xsm:pt-6 sm:pt-6'>Effective Collaboration</p>
                            </div>
                        </Col>
                        <Col className='xsm:!my-4 sm:!my-4' md={4}>
                            <div>
                                <Image className='w-24 mx-auto my-0 drop-shadow-4lg' src={Delivery} />
                                <p className='text-[#282829] font-Prompt relative pt-10 mb-0 font-semibold text-center xsm:pt-6 sm:pt-6'>Quality Delivered on Time</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className='mt-10 mb-10 xsm:mt-6 xsm:mb-6'>
                <div>
                    <Slide direction='right' damping={0.1} flash triggerOnce>
                        <h3 className='text-[#282829] font-Prompt font-bold text-center'>Technology We Expertize</h3>
                    </Slide>
                    <Slide direction='up' triggerOnce>
                        <p className='text-[#737373] mb-0 text-sm font-Prompt text-center pt-3'>FujiSakura Technologies specialized in crafting latest Mobile Application, Software Application, Web Application and also in extending services like Digital Marketing, Quality Testing, Content Development and Management, SEO and through many more.</p>
                    </Slide>
                    <div className='mt-16'>
                        <Tabs
                            defaultActiveKey="mobile"
                            id="uncontrolled-tab-example"
                            className="mb-3 justify-content-center tech-tab"
                        >
                            <Tab className='mt-16 xsm:mt-8' eventKey="mobile" title="Mobile">
                                <Slide direction='down' triggerOnce>
                                    <div className='d-flex align-items-center justify-content-center gap-x-20 xsm:gap-x-8 xsm:flex-wrap'>
                                        <div className='text-center'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Android} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>Android</h5>
                                        </div>
                                        <div className='text-center'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Ios} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>IOS</h5>
                                        </div>
                                        <div className='text-center'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Reactnative} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>React Native</h5>
                                        </div>
                                    </div>
                                </Slide>
                            </Tab>
                            <Tab className='mt-16 xsm:mt-8' eventKey="client" title="Client">
                                <Slide direction='down' triggerOnce>
                                    <div className='d-flex align-items-center justify-content-center gap-x-20 xsm:gap-x-8 xsm:flex-wrap sm:gap-x-8'>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Ts} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>Type Script</h5>
                                        </div>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Js} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>Java Script</h5>
                                        </div>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Reactnative} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>React Js</h5>
                                        </div>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Angular} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>Angular Js</h5>
                                        </div>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Vue} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>Vue Js</h5>
                                        </div>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Nextjs} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>Next Js</h5>
                                        </div>
                                    </div>
                                </Slide>
                            </Tab>
                            <Tab className='mt-16 xsm:mt-8' eventKey="server" title="Server">
                                <Slide direction='down' triggerOnce>
                                    <div className='d-flex align-items-center justify-content-center gap-x-20 xsm:gap-x-8 xsm:flex-wrap sm:gap-x-8'>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Springboot} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>Spring Boot</h5>
                                        </div>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Php} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>Php</h5>
                                        </div>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Laravel} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>Laravel</h5>
                                        </div>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Django} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>Django</h5>
                                        </div>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Net} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>.Net</h5>
                                        </div>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Python} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>Python</h5>
                                        </div>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Nodejs} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>Node Js</h5>
                                        </div>
                                    </div>
                                </Slide>
                            </Tab>
                            <Tab className='mt-16 xsm:mt-8' eventKey="design" title="Design">
                                <Slide direction='down' triggerOnce>
                                    <div className='d-flex align-items-center justify-content-center gap-x-20 xsm:gap-x-8 xsm:flex-wrap sm:gap-x-8'>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Figma} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>Figma</h5>
                                        </div>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Blender} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>Blender</h5>
                                        </div>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Sketch} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>Sketch</h5>
                                        </div>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Adobe} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>Adobe</h5>
                                        </div>
                                        <div className='text-center xsm:my-2'>
                                            <Image className='w-12 h-12 mx-auto object-contain' src={Xd} />
                                            <h5 className='text-[#282829] font-Prompt text-sm mt-3'>Xd</h5>
                                        </div>
                                    </div>
                                </Slide>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </Container>
            <div className='bg-hero-pattern bg-cover bg-no-repeat py-16 px-8 xsm:px-0 xsm:py-8 md:px-4 md:py-8'>
                <Container fluid='fluid'>
                    <Row>
                        <Col md={6}>
                            <div className='bg-[#C9C7D7] pt-8 pb-8 pr-16 pl-8 rounded-xl xsm:pr-4 xsm:pl-4 md:pr-4 md:pl-4'>
                                <h3 className='text-[#282829] font-Prompt font-bold'>Want to accelerate your business growth?</h3>
                                <p className='text-[#282829] mb-0 text-sm font-Prompt'>Discover how personalized website experience can help drive engagement, conversions, and customer loyalty in the digital age.</p>
                                <div className='mt-3'>
                                    <Button className="d-flex align-items-center gap-2.5 !bg-[#4379EE] border-1 !border-[#4379EE] px-3 py-2 !text-sm !rounded-md"><Image className='w-6 h-4' src={Msg} />Continue this conversation</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div
            className='z-10 absolute w-fit top-[80%] xsm:top-[90%] xsm:left-8 sm:top-[90%] md:top-[90%] lg:top-[90%] xl:top-[500px] bottom-0 left-24 h-max rounded-sm flex justify-center items-center px-3 py-2.5 bg-[#504982] hover:opacity-100 cursor-pointer z-20`'
            onClick={onClick}
        >
            <Image className='!w-1.5' src={SlideLeft} />
        </div>
    );
}

const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
        <div
            className='absolute w-fit top-[80%] xsm:top-[90%] xsm:left-24 sm:top-[90%] md:top-[90%] lg:top-[90%] xl:top-[500px] bottom-0 left-40 h-max rounded-sm flex justify-center items-center px-3 py-2.5 bg-[#504982] hover:opacity-100 cursor-pointer z-20`'
            onClick={onClick}
        >
            <Image className='!w-1.5' src={SlideRight} />
        </div>
    );
}


export default Home