import React, { useState } from 'react'
import { Image, Container, Nav, Navbar, Offcanvas, Dropdown, NavDropdown } from "react-bootstrap";
import { NavLink, Outlet } from 'react-router-dom';
import "../../resources/style/style.css"
import "../../resources/images/index"
import { FlagIndia, FlagJapan, FujiLogo } from '../../resources/images/index';
import Footer from '../Footer/Footer';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';

const Navbarlayout = () => {
    // const [show, setShow] = useState(false);
    // const showDropdown = (e) => {
    //     setShow(!show);
    // }
    // const hideDropdown = e => {
    //     setShow(false);
    // }

    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    const handleClose = () => setMenuOpen(false);
    return (
        <>
            {['lg'].map((expand) => (
                <>
                    <Navbar key={expand} expand={expand} className="!py-[5px] !fixed !top-0 w-full !bg-white z-50 shadow-[0px_12px_13px_0px_#00000024]">
                        <Container fluid>
                            <NavLink className='xsm:pl-0 sm:pl-0 pl-5' to={"/"}>
                                <Image className='w-36 h-9' src={FujiLogo} />
                            </NavLink>
                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={toggleMenu} />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="end"
                                className='xsm:!bg-[#E8EDF9] sm:!bg-[#E8EDF9] smd:!bg-[#E8EDF9]'
                                show={menuOpen}
                                onHide={handleClose}
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                        <Image className='w-36 h-9' src={FujiLogo} />
                                    </Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body className='relative'>
                                    <Nav onClick={handleClose} activeKey="/" className="nav-tab items-center justify-content-center flex-grow-1 xsm:items-start sm:items-start smd:items-start">
                                        <Nav.Item className='xsm:my-2.5 sm:my-2.5 smd:my-2.5'>
                                            <NavLink className="px-[1rem] !font-JosefinSans pt-[1.5rem] text-base font-semibold hover:text-[#4379EE] mx-3 no-underline text-navbar-color before:content-content-[''] before:w-0 before:h-1 before:absolute before:-bottom-1 before:right-0 before:bg-[#4379EE]-400 before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:before:bg-blue-500 relative xsm:pt-[11px] sm:!p-[11px] smd:!p-[11px] xsmd:px-[10px]" to={"/"}>
                                                Home
                                            </NavLink>
                                        </Nav.Item>
                                        <Nav.Item className='xsm:my-2.5 sm:my-2.5 smd:my-2.5'>
                                            <NavLink className="px-[1rem] !font-JosefinSans pt-[1.5rem] text-base font-semibold hover:text-[#4379EE] mx-3 no-underline text-navbar-color before:content-content-[''] before:w-0 before:h-1 before:absolute before:-bottom-1 before:right-0 before:bg-[#4379EE]-400 before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:before:bg-blue-500 relative xsm:pt-[11px] sm:!p-[11px] smd:!p-[11px] xsmd:px-[10px]" to={"/company"}>
                                                Company
                                            </NavLink>
                                        </Nav.Item>
                                        <Nav.Item className='xsm:my-2.5 sm:my-2.5 smd:my-2.5'>
                                            <NavLink className="px-[1rem] !font-JosefinSans pt-[1.5rem] text-base font-semibold hover:text-[#4379EE] mx-3 no-underline text-navbar-color before:content-content-[''] before:w-0 before:h-1 before:absolute before:-bottom-1 before:right-0 before:bg-[#4379EE]-400 before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:before:bg-blue-500 relative xsm:pt-[11px] sm:!p-[11px] smd:!p-[11px] xsmd:px-[10px]" to={"/service"}>
                                                Service
                                            </NavLink>
                                        </Nav.Item>
                                        <Nav.Item className='xsm:my-2.5 sm:my-2.5 smd:my-2.5'>
                                            <NavLink className="px-[1rem] !font-JosefinSans pt-[1.5rem] text-base font-semibold hover:text-[#4379EE] mx-3 no-underline text-navbar-color before:content-content-[''] before:w-0 before:h-1 before:absolute before:-bottom-1 before:right-0 before:bg-[#4379EE]-400 before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:before:bg-blue-500 relative xsm:pt-[11px] sm:!p-[11px] smd:!p-[11px] xsmd:px-[10px]" to={"/carrer"}>
                                                Career
                                            </NavLink>
                                        </Nav.Item>
                                        <Nav.Item className='xsm:my-2.5 sm:my-2.5 smd:my-2.5'>
                                            <NavLink className="px-[1rem] !font-JosefinSans pt-[1.5rem] text-base font-semibold hover:text-[#4379EE] mx-3 no-underline text-navbar-color before:content-content-[''] before:w-0 before:h-1 before:absolute before:-bottom-1 before:right-0 before:bg-[#4379EE]-400 before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:before:bg-blue-500 relative xsm:pt-[11px] sm:!p-[11px] smd:!p-[11px] xsmd:px-[10px]" to={"/director"}>
                                                Leadership
                                            </NavLink>
                                        </Nav.Item>
                                        <Nav.Item className='xsm:my-2.5 sm:my-2.5 smd:my-2.5'>
                                            <NavLink className="px-[1rem] !font-JosefinSans pt-[1.5rem] text-base font-semibold hover:text-[#4379EE] mx-3 no-underline text-navbar-color before:content-content-[''] before:w-0 before:h-1 before:absolute before:-bottom-1 before:right-0 before:bg-[#4379EE]-400 before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:before:bg-blue-500 relative xsm:pt-[11px] sm:!p-[11px] smd:!p-[11px] xsmd:px-[10px]" to={"/contact"}>
                                                Contact
                                            </NavLink>
                                        </Nav.Item>
                                        {/* <Dropdown
                                            id={`offcanvasNavbarDropdown-expand-${expand}`}
                                            show={show}
                                            onMouseEnter={showDropdown}
                                            onMouseLeave={hideDropdown}
                                            className="px-3 !font-JosefinSans text-base font-semibold hover:text-[#4379EE] mx-3 no-underline text-navbar-color before:content-content-[''] before:w-0 before:h-1 before:absolute before:-bottom-1 before:right-0 before:bg-[#4379EE]-400 before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:before:bg-blue-500 relative"
                                        >
                                            <Dropdown.Toggle className='!bg-transparent border-0 !text-[#737373]'>Company</Dropdown.Toggle>
                                            <Dropdown.Menu className='navdrop'>
                                                <Dropdown.Item>
                                                    <NavLink className='no-underline text-navbar-color' to={"/company"}>Corporate Information</NavLink>
                                                </Dropdown.Item>
                                                <Dropdown.Item>
                                                    <NavLink className='no-underline text-navbar-color' to={"/director"}>Leadership</NavLink>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                                        {/* <NavDropdown show={show}
                                            onMouseEnter={showDropdown}
                                            onMouseLeave={hideDropdown}
                                            title="Company"
                                            id="nav-dropdown"
                                            className="navdrop px-3 !font-JosefinSans py-1.5 text-base text-navbar-color font-semibold hover:!text-[#4379EE] mx-3 no-underline text-navbar-color before:content-content-[''] before:w-0 before:h-1 before:absolute before:-bottom-1 before:right-0 before:bg-[#4379EE]-400 before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:before:bg-blue-500 relative"
                                        >
                                            <NavDropdown.Item className='pt-2 active:!bg-transparent'>
                                                <NavLink className='no-underline text-navbar-color' to={"/company"}>Corporate Information</NavLink>
                                            </NavDropdown.Item>
                                            <NavDropdown.Item className='pt-2 active:!bg-transparent'>
                                                <NavLink className='no-underline text-navbar-color' to={"/director"}>Leadership</NavLink>
                                            </NavDropdown.Item>
                                        </NavDropdown> */}
                                    </Nav>
                                    <Dropdown className='xsm:mt-5 sm:mt-5 smd:mt-5 !absolute top-0 right-0'>
                                        <Dropdown.Toggle className="d-flex align-items-center bg-transparent border-0 !px-4 pb-0 pt-0 drop-showbtn xsm:before:content-none sm:before:content-none smd:before:content-none before:content-[''] before:absolute before:bg-[#999999] before:w-0.5 before:h-full before:left-0 before:top-0 before:rounded-sm after:content-[''] after:bg-dropdown-pattern after:w-5 after:h-3 after:bg-cover after:ml-1 after:-mt-1 after:bg-no-repeat" id="dropdown-basic">
                                            <Image className='flag-icon w-7 h-7' src={FlagIndia} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='drop-show !-left-16'>
                                            <Dropdown.Item href="#/action-1">
                                                <div className='d-flex align-items-center gap-3'>
                                                    <Image className='flag-icon w-7 h-7' src={FlagJapan} />
                                                    <span>Japanese</span>
                                                </div>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container >
                    </Navbar >
                </>
            ))}
            <Outlet />
            <Footer />
        </>
    )
}

export default Navbarlayout